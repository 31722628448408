import React, { Component } from "react";
import Navigation from "../Navbar";
import SiteSelect from "../Global/Site_select2";
import { Fetch } from "../../helpers/Fetch";
import TenantReportReactTable from "./TenantReportReactTable";
import AppFooter from "../AppFooter";
import SiteNotesTextArea from "../Global/SiteNotesTextArea";

class Tenantv3 extends Component {
  constructor(props) {
    super(props);
    this.viewConsump = "usage"; //default hide units with no usage
    this.state = {
      site_tz: "",
      site_id: sessionStorage.getItem("prev_site_id"),
      viewConsump: this.viewConsump,
      offset: 0,
      table_data: [],
      table_dates: [],
      context_menu: [],
      category_rows: [],
      toggle_consumption: false,
      loading: true,
      login_data: JSON.parse(localStorage.getItem("login_data")),
      site_notes:sessionStorage.getItem("prev_site_notes")
    };
  }
  componentDidMount() {
    const { site_id } = this.state;
    if (site_id) {
      this.getTenantsTableData();
    }
  }

  handleOffsetChange = (e, val) => {
    const { offset, table_dates } = this.state;
    let computed_val = offset + val;
    let disable_pagination = false; //default value
    table_dates.forEach((date) => {
      if (date === "") {
        //If a date is empty - no more panning to the right/future
        //console.log("date === empty disable_pagination");
        disable_pagination = true;
      }
    });

    if (computed_val >= 0) {
      //console.log("computed_val >= 0");
      if (val !== "current" && offset >= 0) {
        //console.log("val !== current && this.offset >= 0");
        this.setState(
          {
            offset: offset + val,
          },
          () => this.getTenantsTableData()
        );
      }
    } else if (computed_val < 0) {
      this.setState(
        {
          offset: 0,
        },
        () => this.getTenantsTableData()
      );
    }

    if (val === "current") {
      //console.log("val === current");
      this.setState(
        {
          offset: 0,
        },
        () => this.getTenantsTableData()
      );
    } else {
      this.setState({
        disable_pagination: disable_pagination,
      });
    }
  };

  getTenantsTableData = (consumption) => {
    const { offset, site_id } = this.state;
    this.setState({
      loading: true
    })
    Fetch("TenantV3_api.php", {
      action: "fill_tenant_table",
      site_id: site_id,
      viewConsump: this.viewConsump, //Return all rows including reports with all zero consumption
      offset: offset,
    }).then((data) => {
      if (data.status === "ok") {
        console.log(data);
        let massaged_dates = data.dates;
        while (massaged_dates.length < 6) {
          massaged_dates.push("");
        }

        this.setState({
          disable_pagination: data.dates.length < 6,
          report_groups: data.rg_rows,
          table_data: data.data2,
          table_dates: data.dates.reverse(),
          category_rows: data.rg_rows,
          context_menu: data.contextMenu,
          loading: false,
        });
      } else {
        console.log("Fetch ERROR", data);
      }
    });
  };

  handleSiteChange = (e) => {
    this.setState(
      {
        site_tz: e.site_tz,
        site_id: e.site_id
      },
      () => this.getTenantsTableData()
    );
  };

  toggleConsumption = () => {
    this.viewConsump = this.viewConsump === "usage" ? "all" : "usage";
    this.getTenantsTableData();
  };

  handleTableRefresh = () => {
    this.setState(
      {
        loading: true,
      },
      () => this.getTenantsTableData()
    );
  };

 handleSiteNotesChange = (value)=>{
    this.setState({
      site_notes: value
    });
  }
  render() {
    const {
      table_data,
      table_dates,
      site_tz,
      category_rows,
      disable_pagination,
      toggle_consumption,
      loading,
      site_id,
      site_notes
    } = this.state;
    return (
      <div>
        <Navigation />
        <div>
          <div className="col-4 offset-4 mb-2" align="center">
            <SiteSelect handleSiteChange={this.handleSiteChange} site_notes={site_notes} />
            <SiteNotesTextArea site_id={site_id} handleSiteNotesChange={this.handleSiteNotesChange}/>
          </div>
          {/*<TenantsReportTable table_data={table_data} reportDates={table_dates} site_tz={site_tz} />*/}
          {table_data.length > 0 && (
            <TenantReportReactTable
              table_data={table_data}
              report_dates={table_dates}
              site_tz={site_tz}
              category_rows={category_rows}
              refresh_table={this.handleTableRefresh}
              handle_change_offset={this.handleOffsetChange}
              disable_pagination={disable_pagination}
              toggle_consumption={this.toggleConsumption}
              loading={loading}
              toggle_consumption_val={toggle_consumption}
            />
          )}
        </div>
        {!loading && <AppFooter />}
      </div>
    );
  }
}

export default Tenantv3;
