import React, {Component} from "react";
import Navigation from "../Navbar";
import AppFooter from "../AppFooter";
import "./Devices.css";

import SiteSelect from "../Global/Site_select2";

import CollectorTable3 from "./CollectorTable3";
import SiteNotesTextArea from "../Global/SiteNotesTextArea";

class Devices extends Component {
    constructor(props) {
        super(props);
        this.state = {
            site_id: sessionStorage.getItem("prev_site_id"),
            site_tz: sessionStorage.getItem("prev_site_tz"),
            site_notes:sessionStorage.getItem("prev_site_notes"),
            expanded: [],
        };
    }


    handleSiteChange = (e) => {
        this.setState({
            site_tz: e.site_tz,
            site_id: e.value,
        });
    };

    handleSiteNotesChange=(value)=>{
        this.setState({
            site_notes: value
        });
    }


    render() {
        const {site_id, site_tz, site_notes} = this.state

        return (
            <div>
                <Navigation/>
                <div className="container-fluid">
                    <div className="col-sm-8 col-lg-4 col-xl-3 mx-auto">
                        <SiteSelect handleSiteChange={this.handleSiteChange} site_notes={site_notes}/>
                        <SiteNotesTextArea site_id={site_id} handleSiteNotesChange={this.handleSiteNotesChange}/>
                    </div>
                    <div className="mx-auto col-xxl-10 col-xl-12">
                        <CollectorTable3
                            site_id={site_id}
                            site_tz={site_tz}
                        />
                    </div>
                </div>
                <AppFooter/>
            </div>
        );
    }
}

export default Devices;
