export function SpinnerOn() {

    let root_elem = document.getElementById('root')
    root_elem.style.pointerEvents = 'none'
    root_elem.style.opacity = '0.4'
    if(document.getElementById('app-spinner')){
        let elem = document.getElementById('app-spinner')
        return elem.style.display = "block"
    }


}