import * as React from 'react'
import {useEffect, useRef, useMemo} from "react";
import Table from 'react-bootstrap/Table';

import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    useReactTable,
} from '@tanstack/react-table'
import {CurrencyFormat} from "../../helpers/CurrencyFormat";
import {Fetch} from "../../helpers/Fetch";
import {Button, Modal} from "react-bootstrap";

export default function ExtraLineTable(params) {
    const [update, setUpdate] = React.useState({count: 0});

    const [extended_total, setExtended_total] = React.useState(0)
    const [item_id, setItemId] = React.useState('');
    const [delete_modal, setDeleteModal] = React.useState(false);
    let data = useRef([])
    let rate_effective_id = useRef(0)
    let site_id = useRef(0)
    const nextFocus = useMemo(
        () => ({id: ''}),
        [] //no dependencies so the value doesn't change
    );

    // console.log('**************** render ExtraLineTable ******************', update, site_id.current, rate_effective_id.current, params, data)

    /**
     * Get data from DB
     */
    useEffect(() => {
        if ((rate_effective_id.current !== params.rate_effective_id) || (site_id.current !== params.site_id)) {
            site_id.current = params.site_id
            rate_effective_id.current = params.rate_effective_id
            getData()
        }
        // eslint-disable-next-line
    }, [update.count, params.site_id, params.rate_effective_id])

    useEffect(() => {
        if (params.extra_charge_added) {
            getData()
            params.handleExtraChargeAdded()
        }
        // eslint-disable-next-line
    }, [params.extra_charge_added])

    function getData() {
        Fetch("extra_line_items_api.php", {
            action: "fill_extras_table",
            site_id: params.site_id,
            rate_effective_id: params.rate_effective_id,
        }).then((fdata) => {
            if (fdata.status === "ok") {
                data.current = getSortedData(fdata.data)

                //total extended column
                // console.log('data', data)
                let extended_total = 0;

                // iterate over each item in the array
                for (let i = 0; i < data.current.length; i++) {
                    // console.log(data.current[i])
                    extended_total += Number(data.current[i].extended);
                }

                // console.log(extended_total)
                setExtended_total(extended_total);

                //Trigger refresh
                setUpdate((prev) => ({
                    ...prev,
                    count: prev.count + 1
                }))
            }
        });
    }

    function getSortedData(data) {
        return data.sort((a, b) => (a.report_name + a.item_id).localeCompare(b.report_name + b.item_id))
    }

    /**
     * Does simple min/max validation of numbers
     * error defaults to an empty string or is-invalid if failed
     * errorText defaults to an empty string or a message if failed
     * @param {real} min
     * @param {real} max
     * @param {real} value
     * @returns {{errorText: string, error: string}}
     */
    function doSimpleValidation(max, value) {
        let errorObj = {error: '', errorText: ''}

        if (value > max) //value greater than max value
            errorObj = {
                error: ' is-invalid',
                errorText: 'Value must be less than ' + max
            }
        return errorObj
    }

    function handleKeypress(e) {
        if ((e.key === 'Enter') || (e.key === 'Tab')) { //Enter or Tab key pressed
            e.preventDefault()
            getFocus(e)
        }
    }

    function getFocus(e) {
        //Get/set the next focus field
        let nextInput = document.querySelectorAll('[tabIndex="' + (e.target.tabIndex + 1) + '"]');
        if (nextInput.length === 0) {
            nextInput = document.querySelectorAll('[tabIndex="1"]');
        }
        nextInput[0].focus();//triggers onBlur event for the input to write to DB
        nextFocus.id = nextInput[0].id
    }

    function write_DB(e, accessor) {
        // console.log('accessor', accessor)
        if (e.target.value !== e.target.defaultValue) {
            //If the value is not the same as the defaultValue/value from DB
            //Get the current/next focus field
            let nextInput = document.querySelectorAll('[tabIndex="' + (e.target.tabIndex + 1) + '"]');
            if (nextInput.length === 0) { //Default tabIndex="1"
                nextInput = document.querySelectorAll('[tabIndex="1"]');
            }
            let new_val = e.target.value === "" ? "0" : e.target.value
            let id = e.target.id

            console.log('table cell/row data', id)

            //Field to update
            //Example ID: accessor_2400_2014_0 where accessor is the DB name of the field
            //c.report_bill_id + "_" + c.energy_user_id + "_" + c.item_id
            let field = id.substring(0, accessor.length)

            //Get report_bill_id
            let x = id.indexOf('_', accessor.length) //First _ after accessor
            let y = id.indexOf('_', x + 1) //Second _

            //Get energy_user_id
            x = id.indexOf('_', y + 1) //Third _

            //Get item_id
            let item_id = id.substring(x + 1)

            let data = {
                action: "update" + field,
                item_id: item_id
            }
            if (field === 'quantity') data.quantity = new_val
            else if (field === 'item_description') data.item_description = new_val
            else data.charge_amount = new_val

            console.log('Fetch body', nextInput[0].id, data)

            //This updates whatever column was changed by the user
            Fetch("extra_line_items_api.php", data).then((fdata) => {
                if (fdata.status === "ok") {
                    nextFocus.id = nextInput[0].id
                    getData()
                } else {
                    console.log('status not OK', fdata)
                }
            })
        }
    }

    function handleOnBlur(e, accessor) {
        e.preventDefault()
        write_DB(e, accessor)
    }

    function handleDelete() {
        Fetch("extra_line_items_api.php", {
            action: "delete_extras",
            item_id: item_id
        }).then((data) => {
            if (data.status === "ok") {
                setDeleteModal(false)
                setItemId('')
                getData()
            }
        });
    }

    function handleOpenDeleteModal(item_id) {
        setItemId(item_id)
        setDeleteModal(true)
    }

    function handleCloseDeleteModal() {
        setDeleteModal(false)
    }

    function processCell(cell, accessor) {
        // if ((accessor === "charge_amount")&& (cell.row.original.report_bill_id===2398)) {
        //     console.log("processCell cell", accessor, cell)
        //
        // }
        //Generate input id with the parts required to update DB
        let c = cell.row.original
        let input_id = accessor + "_" + c.report_bill_id + "_" + c.energy_user_id + "_" + c.item_id

        //Get row number in table
        let r = table.getCoreRowModel().rows
        let row_num = r.findIndex((row) => {
            return row.original === cell.row.original
        })

        return {'input_id': input_id, 'row_num': row_num}
    }

    const columnHelper = createColumnHelper()

    const columns = () => {
        return [
            columnHelper.accessor('action', {
                    header: () => '',
                    cell: (props) => {
                        return (
                            <div className="text-center ms-2 me-2" style={{cursor: 'pointer'}}>
                                <i
                                    className="fa fa-trash"
                                    style={{color: "red"}}
                                    aria-hidden="true"
                                    onClick={() => handleOpenDeleteModal(props.row.original.item_id)}
                                />
                            </div>
                        )
                    }
                }
            ),
            columnHelper.accessor('report_bill_id', {
                header: () => 'RBID',
                cell: info => info.getValue(),
            }),
            columnHelper.accessor('energy_user_id', {
                header: () => 'EUID',
                cell: info => info.getValue(),
            }),
            columnHelper.accessor('report_name', {
                header: () => 'Space',
                cell: info => info.getValue(),
            }),
            columnHelper.accessor('org_name', {
                header: () => 'Tenant Name',
                cell: info => info.getValue(),
            }),
            columnHelper.accessor('quantity', {
                    header: () => 'Quantity',
                    cell: cell => {
                        let accessor = 'quantity'
                        let cellObj = processCell(cell, accessor)

                        const OnBlurEvent = (e) => {
                            handleOnBlur(e, accessor)
                        }

                        const KeyPressEvent = (e) => {
                            handleKeypress(e)
                        }

                        //Put the focus on the next field
                        let autoFocus = false
                        if (nextFocus.id === cellObj.input_id) {
                            autoFocus = true
                        }

                        let validate = doSimpleValidation(100000, cell.getValue())

                        //quantity
                        return (
                            <div className="input-group-sm">
                                <input className={"form-control text-end" + validate.error}
                                       id={cellObj.input_id}
                                       defaultValue={cell.getValue()}
                                       type="number"
                                       tabIndex={cellObj.row_num}
                                       onKeyDown={(e) => KeyPressEvent(e)}
                                       onBlur={(e) => OnBlurEvent(e)}
                                       autoFocus={autoFocus}
                                />
                                <div className="invalid-feedback">
                                    {validate.errorText}
                                </div>
                            </div>
                        )
                    },
                }
            ),
            columnHelper.accessor('charge_amount', {
                    header: () => 'Unit Charge',
                    cell: cell => {

                        //console.log('charge_amount cell', cell)
                        let accessor = 'charge_amount'
                        let cellObj = processCell(cell, accessor)
                        let validate = doSimpleValidation(100000, cell.getValue())
                        const OnBlurEvent = (e) => {
                            handleOnBlur(e, accessor)
                        }

                        const KeyPressEvent = (e) => {
                            handleKeypress(e)
                        }

                        const handleInput = (e) => {
                            const {value} = e.currentTarget;
                            let newValue = 0
                            // Remove currency format
                            newValue = value.replace(/[^\d.,]/g, '');
                            // Allow decimals
                            newValue = newValue.replace(/,(?=\d{3})/g, '');
                            e.currentTarget.value = newValue;
                        };

                        //Put the focus on the next field
                        let autoFocus = false
                        if (nextFocus.id === cellObj.input_id) {
                            autoFocus = true
                        }

                        //charge_amount
                        return (
                            <div className="input-group input-group-sm">
                                <span className="input-group-text" id="inputGroup-sizing-sm">$</span>
                                <input className={"form-control text-end" + validate.error}
                                       id={cellObj.input_id}
                                       onInput={handleInput}
                                       defaultValue={CurrencyFormat(5).format(cell.getValue())}
                                       type="text"
                                       tabIndex={cellObj.row_num + 1000}
                                       onKeyDown={(e) => KeyPressEvent(e)}
                                       onBlur={(e) => OnBlurEvent(e)}
                                       autoFocus={autoFocus}
                                />
                                <div className="invalid-feedback">
                                    {validate.errorText}
                                </div>
                            </div>
                        )
                    }
                }
            ),
            columnHelper.accessor('extended', {
                header: () => 'Extended total: $' + CurrencyFormat().format(extended_total),
                cell: cell => {
                    return (
                        <div className="input-group input-group-sm">
                            <span className="input-group-text" id="inputGroup-sizing-sm">$</span>
                            <input className="form-control text-end"
                                   defaultValue={CurrencyFormat().format(cell.getValue())}
                                   type="text"
                                   disabled
                            />
                        </div>
                    )
                }
            }),
            columnHelper.accessor('item_description', {
                    header: () => 'Description',
                    cell: cell => {
                        let accessor = 'item_description'
                        let cellObj = processCell(cell, accessor)

                        const OnBlurEvent = (e) => {
                            handleOnBlur(e, accessor)
                        }

                        const KeyPressEvent = (e) => {
                            handleKeypress(e)
                        }

                        //Put the focus on the next field
                        let autoFocus = false
                        if (nextFocus.id === cellObj.input_id) {
                            autoFocus = true
                        }

                        return (
                            <div className="input-group-sm" style={{minWidth: '250px'}}>
                                <input className={"form-control"}
                                       id={cellObj.input_id}
                                       defaultValue={cell.getValue()}
                                       type="text"
                                       tabIndex={cellObj.row_num + 2000}
                                       onKeyDown={(e) => KeyPressEvent(e)}
                                       onBlur={(e) => OnBlurEvent(e)}
                                       autoFocus={autoFocus}
                                />
                            </div>
                        )
                    }
                }
            ),
            columnHelper.accessor('item_id', {
                header: () => 'Item',
                cell: info => info.getValue(),
            }),
        ]
    }

    // console.log('Table data', data.current)
    const table = useReactTable({
        data: data.current,
        columns: columns(),
        getCoreRowModel: getCoreRowModel(),
        state: {
            columnVisibility: {
                 'item_id': false,
            },
        },
        debugTable: true,
    })

    return (
        <div className="table-responsive">
            {data.current.length > 0 && (
                <>
                    <Table bordered size="sm">
                        <thead>
                        {table.getHeaderGroups().map(headerGroup => (
                            <tr key={headerGroup.id}>
                                {headerGroup.headers.map(header => (
                                    <th key={header.id}>
                                        {header.isPlaceholder
                                            ? null
                                            : flexRender(
                                                header.column.columnDef.header,
                                                header.getContext()
                                            )}
                                    </th>
                                ))}
                            </tr>
                        ))}
                        </thead>
                        <tbody>
                        {table.getCoreRowModel().rows.map(row => (
                            <tr key={row.id}>
                                {row.getVisibleCells().map(cell => (
                                    <td key={cell.id}>
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </td>
                                ))}
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                    <Modal show={delete_modal} onHide={handleCloseDeleteModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>Delete Extra Charge</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>Are you sure you want to delete this entry?</Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleCloseDeleteModal}>
                                Cancel
                            </Button>
                            <Button variant="danger" onClick={handleDelete}>
                                Delete
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </>
            )}
        </div>
    )
}