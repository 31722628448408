import React, { Component } from "react";

export default class SearchBar extends Component {
  handleFilterTextChange = e => {
    this.props.onFilterTextChange(e.target.value);
  };

  render() {
    return (

        <form>
          <input
            type="search"
            placeholder="Search..."
            className="form-control col-1 light"
            value={this.props.filterText}
            onChange={this.handleFilterTextChange}
          />
        </form>

    );
  }
}
