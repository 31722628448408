import React, {PureComponent} from "react";

import dayjs from 'dayjs';
// import utc from 'dayjs/plugin/utc';
// import timezone from 'dayjs/plugin/timezone';

import NonElecRow from "./NonElecRow";
import NonElecCategory from "./NonElecCategory";
import Fuse from "fuse.js";
// import NonElectricGraph from "./NonElectricGraph";
// import {TenantModal} from "../Tenantv2/TenantModal";
import {NonElecGraph} from "./NonElecGraph";


class NonElecTable extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            show_graph_modal: false
        }
    }

    handleShowGraphModal = (device_id, param_id, device_name) => {
        // console.log('handleShowGraphModal', device_id, param_id, device_name);
        this.setState({
            show_graph_modal: true,
            device_id: device_id,
            param_id: param_id,
            device_name: device_name
        });
    };

    handleGraphModalClose = () => {
        this.setState({
            show_graph_modal: false
        });
    };

    render() {
        // console.log('NonElecTable render');
        const {
            non_elec_data,
            filterText,
            panned_date,
            dates,
            site_tz
        } = this.props;

        const {show_graph_modal, device_id, param_id, device_name} = this.state;

        let rows = [];

        /******************************************************************************
         *    Set up and perform row filtering based on text search box
         ******************************************************************************/
        const fuse = new Fuse(non_elec_data, {
            keys: ["device_name", "units"],
            threshold: 0,
            ignoreLocation: true,
            isCaseSensitive: false //false is the default but I want this comment as a reminder
        });

        const search_results = fuse.search(filterText);
        const nonElecData = filterText
            ? search_results.map(result => result.item)
            : non_elec_data;

        /******************************************************************************
         *    Generate main table column date headers for the table
         ******************************************************************************/
        let date_0 = ""; //This date is excel exported but not used on screen
        let date_1 = "";
        let date_2 = "";
        let date_3 = "";
        let date_4 = "";
        let date_5 = "";
        // let date_6 = "";

        if (dates !== undefined) {
            //if fetch done
            date_0 = dayjs(dates[0]).format("MMM YYYY");
            date_1 = dayjs(dates[1]).format("MMM YYYY");
            date_2 = dayjs(dates[2]).format("MMM YYYY");
            date_3 = dayjs(dates[3]).format("MMM YYYY");
            date_4 = dayjs(dates[4]).format("MMM YYYY");
            date_5 = dayjs(dates[5]).format("MMM YYYY");
            // date_6 = dayjs(dates[6]).format("MMM YYYY");
        }

        /******************************************************************************
         *    Calculate category/section totals for each column
         ******************************************************************************/

            //Initialise loop variables
        let lastCategory = -1;
        let cat_start_row_idx = 0;

        //Row data
        let yesterday_data;
        let date_1_data;
        let date_2_data;
        let date_3_data;
        let date_4_data;
        let date_5_data;
        let date_6_data;

        //Section totals
        let yester_tot = 0;
        let date_1_tot = 0;
        let date_2_tot = 0;
        let date_3_tot = 0;
        let date_4_tot = 0;
        let date_5_tot = 0;
        let date_6_tot = 0;

        //Sort the array by param_id and device_name
        // console.log("A Entering loop", nonElecData);
        nonElecData.sort(
            (a, b) =>
                a.param_id - b.param_id || a.device_name.localeCompare(b.device_name)
        );
        // console.log("B Entering loop", nonElecData);

        nonElecData &&
        nonElecData.forEach((elec_data, i) => {
            //console.log("elec_data", elec_data);
            // console.log("A yester_tot", yester_tot);

            if (lastCategory === -1) {
                //first row
                lastCategory = elec_data.param_id;
            } else {
                //Not first row
                if (lastCategory !== elec_data.param_id) {
                    //Not the same category
                    //Insert section/category header row

                    //Delete old header row
                    let idx = rows.findIndex(o => {
                        //console.log(o);
                        return o.key === lastCategory.toString();
                    });
                    if (idx !== -1) {
                        rows.splice(idx, 1);
                    }

                    // console.log("lastCategory", lastCategory);
                    rows.splice(
                        cat_start_row_idx,
                        0,
                        <NonElecCategory
                            key={lastCategory}
                            cat_name={lastCategory === 24 ? "Gallons Dom Water" : "Tons CW"}
                            yester_tot={yester_tot}
                            date_1_tot={date_1_tot}
                            date_2_tot={date_2_tot}
                            date_3_tot={date_3_tot}
                            date_4_tot={date_4_tot}
                            date_5_tot={date_5_tot}
                            date_6_tot={date_6_tot}
                        />
                    );
                    cat_start_row_idx = i + 1;

                    //Reset category/section totals
                    yester_tot = 0;
                    date_1_tot = 0;
                    date_2_tot = 0;
                    date_3_tot = 0;
                    date_4_tot = 0;
                    date_5_tot = 0;
                    date_6_tot = 0;

                    lastCategory = elec_data.param_id;
                }
            }

            switch (elec_data.param_id) {
                case 24: //water gallons
                    yesterday_data = elec_data.yesterday;
                    yester_tot += Number(yesterday_data);

                    date_1_data = elec_data.p1;
                    date_2_data = elec_data.p2;
                    date_3_data = elec_data.p3;
                    date_4_data = elec_data.p4;
                    date_5_data = elec_data.p5;
                    date_6_data = elec_data.p6;


                    break;
                case 29: //BTU - Convert to Tons / 12000
                    yesterday_data = elec_data.yesterday / 12000;
                    yester_tot += Number(yesterday_data);

                    date_1_data = elec_data.p1 / 12000;

                    date_2_data = elec_data.p2 / 12000;

                    date_3_data = elec_data.p3 / 12000;

                    date_4_data = elec_data.p4 / 12000;

                    date_5_data = elec_data.p5 / 12000;

                    date_6_data = elec_data.p6 / 12000;

                    // date_6_data =
                    //   (Number(elec_data.last_reading) - Number(elec_data.p7_start)) /
                    //   12000;

                    break;
                default:
                    console.log("Unidentified param_id", elec_data.param_id);
            }

            //Section totals
            date_1_tot += Number(date_1_data);
            date_2_tot += Number(date_2_data);
            date_3_tot += Number(date_3_data);
            date_4_tot += Number(date_4_data);
            date_5_tot += Number(date_5_data);
            date_6_tot += Number(date_6_data);

            //Row total
            let row_total =
                Number(date_1_data) +
                Number(date_2_data) +
                Number(date_3_data) +
                Number(date_4_data) +
                Number(date_5_data) +
                Number(date_6_data);

            //console.log("row_total", row_total);
            rows.push(
                <NonElecRow
                    handleShowGraphModal={this.handleShowGraphModal}
                    key={elec_data.param_id + "_" + elec_data.device_id}
                    param_id={elec_data.param_id}
                    site_tz={site_tz}
                    device_name={elec_data.device_name}
                    last_reading={elec_data.last_reading}
                    device_notes={elec_data.device_notes}
                    ip_addr={elec_data.ip_addr}
                    scale_factor={elec_data.scale_factor}
                    gmt_last_reading={elec_data.gmt_last_reading}
                    device_id={elec_data.device_id}
                    units={elec_data.units}
                    yesterday={yesterday_data}
                    date_1={date_1_data}
                    date_2={date_2_data}
                    date_3={date_3_data}
                    date_4={date_4_data}
                    date_5={date_5_data}
                    date_6={date_6_data}
                    p1_start={
                        Number(elec_data.p1_start) //+ Number(elec_data.p1_end_correction)
                    }
                    p2_start={
                        Number(elec_data.p2_start) //+ Number(elec_data.p1_end_correction)
                    }
                    p3_start={
                        Number(elec_data.p3_start) //+ Number(elec_data.p2_end_correction)
                    }
                    p4_start={
                        Number(elec_data.p4_start) //+ Number(elec_data.p3_end_correction)
                    }
                    p5_start={
                        Number(elec_data.p5_start) //+ Number(elec_data.p4_end_correction)
                    }
                    p6_start={
                        Number(elec_data.p6_start) //+ Number(elec_data.p5_end_correction)
                    }
                    p7_start={
                        Number(elec_data.p7_start) //+ Number(elec_data.p6_end_correction)
                    }
                    total={row_total}
                />
            );
        });

        // console.log("Before delete", lastCategory, "rows", rows);

        //Delete old header row
        let idx = rows.findIndex(o => {
            //console.log(o);
            return o.key === lastCategory.toString();
        });
        if (idx !== -1) {
            rows.splice(idx, 1);
        }

        // console.log("After delete", lastCategory, "rows", rows);

        //Insert FINAL section/category header row
        rows.splice(
            cat_start_row_idx,
            0,
            <NonElecCategory
                key={lastCategory}
                cat_name={lastCategory === 24 ? "Gallons Dom Water" : "Tons CW"}
                yester_tot={yester_tot}
                date_1_tot={date_1_tot}
                date_2_tot={date_2_tot}
                date_3_tot={date_3_tot}
                date_4_tot={date_4_tot}
                date_5_tot={date_5_tot}
                date_6_tot={date_6_tot}
            />
        );

        // console.log("rows", rows);

        return (
                <div>
                    {nonElecData.length > 0 ? (
                        <table
                            className="table table-bordered table-responsive table-sm"
                            id="tenants-table"
                        >
                            <thead className="sticky-cc-thead">
                            <tr>
                                <th className="text-center">Device Name</th>
                                <th className="text-center">Units</th>
                                <th className="text-center">Yesterday</th>
                                <th className="text-center">{date_0}</th>
                                <th className="text-center">{date_1}</th>
                                <th className="text-center">{date_2}</th>
                                <th className="text-center">{date_3}</th>
                                <th className="text-center">{date_4}</th>
                                <th className="text-center">
                                    {panned_date < 0 ? date_5 : date_5 + " to date"}
                                </th>
                                <th className="text-center">TOTAL</th>
                            </tr>
                            </thead>
                            <tbody>{rows}</tbody>
                        </table>
                    ) : (
                        ""
                    )}

                    {
                        show_graph_modal &&
                        <NonElecGraph
                            show_graph_modal={show_graph_modal}
                            device_id={device_id}
                            param_id={param_id}
                            device_name={device_name}
                            handleGraphModalClose={this.handleGraphModalClose}
                            site_tz= {site_tz || sessionStorage.getItem("prev_site_tz")}
                        />
                    }
                </div>
        );
    }
}

export default NonElecTable;
