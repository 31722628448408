import React, { Component } from "react";
import Navigation from "../Navbar";
import SiteSelect from "../Global/Site_select2";
import GroupSelect from "../Global/GroupSelect";
import { Fetch } from "../../helpers/Fetch";
import Select from "react-select";
import { NumberFormat } from "../../helpers/NumberFormat";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

class ReportHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      site_tz: sessionStorage.getItem("prev_site_tz"),
      report_group_id: sessionStorage.getItem("prev_group_id"),
      site_id: sessionStorage.getItem("prev_site_id"),
      login_data: JSON.parse(localStorage.getItem("login_data")),
      energy_user_data: [],
      energy_user_id: "",
      energy_user_name: "",
      report_history_data: [],
      energy_user_options: [],
    };
    if (sessionStorage.getItem("prev_group_id")) {
      this.handleGetEnergyUser();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { energy_user_data } = this.state;

    if (prevState.site_id !== this.state.site_id) {
      this.handleGetEnergyUser();
    }

    if (prevState.energy_user_data !== this.state.energy_user_data) {
      this.setState({
        energy_user_options:
          energy_user_data &&
          energy_user_data
            .sort(
              (a, b) =>
                a.report_name && a.report_name.localeCompare(b.report_name)
            )
            .map((val) => ({
              label: val.report_name + " - " + val.org_name,
              value: val.energy_user_id,
            })),
      });
      // const energy_user_options =
      //     energy_user_data &&
      //     energy_user_data
      //         .sort(
      //             (a, b) => a.report_name && a.report_name.localeCompare(b.report_name)
      //         )
      //         .map((val) => ({
      //           label: val.report_name + " - " + val.org_name,
      //           value: val.energy_user_id,
      //         }));
    }
  }

  handleCheckReportGroup = () => {
    Fetch(`functions_api.php`, {
      action: "fill_groups_dropdown",
      site_id: this.state.site_id,
    })
      .then((data) => {
        if (data.status === "ok") {
          if (data.data.length === 1) {
            this.setState(
              {
                report_group_id: data.data[0].report_group_id,
              },
              () => this.handleGetEnergyUser()
            );
          }
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  handleGroupSelect = (e) => {
    sessionStorage.setItem("prev_group_id", e.value);
    sessionStorage.setItem("prev_group_name", e.label);
    this.setState(
      {
        report_group_id: e.value,
        report_group_name: e.label,
      },
      () => this.handleGetEnergyUser()
    );
  };

  handleEnergyUserSelect = (e) => {
    this.setState(
      {
        energy_user_id: e.value,
        energy_user_name: e.label,
      },
      () => this.getReportHistory()
    );
  };

  handleGetEnergyUser = () => {
    const { report_group_id } = this.state;

    Fetch(`report_history_api.php`, {
      action: "get_energy_user_id",
      report_group_id: report_group_id,
    })
      .then((data) => {
        if (data.status === "ok") {
          this.setState({
            energy_user_data: data.data,
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  handleSiteChange = (e) => {
    this.setState(
      {
        site_tz: e.site_tz,
        site_id: e.value,
        energy_user_data: [],
        report_history_data: [],
        energy_user_name: "",
        energy_user_id: "",
      },
      () => this.handleCheckReportGroup()
    );
    console.log("handleSiteChange e", e);
    sessionStorage.setItem("prev_site_id", e.value);
    sessionStorage.setItem("prev_site_tz", e.site_tz);
    sessionStorage.setItem("prev_site_name", e.label);
    sessionStorage.setItem("o_value", e.o_value);
    sessionStorage.removeItem("prev_group_id");
    sessionStorage.removeItem("prev_group_name");
  };

  getReportHistory = () => {
    const { energy_user_id } = this.state;
    Fetch(`report_history_api.php`, {
      action: "get_report_history",
      energy_user_id: energy_user_id,
    })
      .then((data) => {
        if (data.status === "ok") {
          this.setState({
            report_history_data: data.data,
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  handleFileDownload = (val) => {
    Fetch(`report_history_api.php`, {
      action: "run_report_cron",
      report_group_id: val.report_group_id,
      energy_user_id: val.energy_user_id,
      report_bill_id: val.report_bill_id,
      report_file_id: val.report_file_id,
    })
      .then((data) => {
        if (data.status === "ok") {
          this.setState({
            energy_user_data: data.data,
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  render() {
    const {
      site_id,
      energy_user_data,
      energy_user_name,
      energy_user_id,
      login_data,
      report_history_data,
      site_tz,
      energy_user_options
    } = this.state;

    return (
      <div>
        <Navigation />
        <div className="col-lg-4 col-xs-12 offset-lg-4">
          <div className="input-group">
            <label style={{ paddingRight: "70px", marginTop: "10px" }}>
              Sites
            </label>
            <div className="col-10">
              <SiteSelect handleSiteChange={this.handleSiteChange} />
            </div>
          </div>

          <div className="input-group">
            {site_id && (
              <>
                <label style={{ paddingRight: "50px", marginTop: "10px" }}>
                  Groups
                </label>
                <div className="col-10">
                  <GroupSelect
                    handleGroupSelect={this.handleGroupSelect}
                    site_id={site_id}
                  />
                </div>
              </>
            )}
          </div>

          <div className="input-group mt-3">
            {energy_user_data.length > 0 && login_data.user_role >= 9000 && (
              <>
                <label style={{ paddingRight: "32px", marginTop: "10px" }}>
                  Energy ID
                </label>
                <div className="col-10">
                  <Select
                    options={energy_user_options}
                    className="mb-3"
                    onChange={(e) => this.handleEnergyUserSelect(e)}
                    value={{
                      label: energy_user_name,
                      value: energy_user_id,
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </div>
        {report_history_data.length > 0 && (
          <table className="table table-bordered">
            <thead className="thead-dark">
              <tr className="text-center">
                <th scope="col">Report From Date</th>
                <th scope="col">Report To Date</th>
                <th scope="col">Sent And Finalized</th>
                <th scope="col">Amount</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {report_history_data
                .sort(
                  (a, b) =>
                    b.report_from_date &&
                    b.report_from_date.localeCompare(a.report_from_date)
                )
                .map((val) => (
                  <tr className="text-center">
                    <td>
                      {dayjs
                        .utc(val.report_from_date)
                        .tz(site_tz)
                        .format("YYYY-MM-DD HH:mm")}
                    </td>
                    <td>
                      {dayjs
                        .utc(val.report_to_date)
                        .tz(site_tz)
                        .format("YYYY-MM-DD HH:mm")}
                    </td>
                    <td>
                      {dayjs
                        .utc(val.sent_and_finalized)
                        .tz(site_tz)
                        .format("YYYY-MM-DD HH:mm")}
                    </td>
                    <td>{"$" + NumberFormat(2).format(val.invoice_amount)}</td>
                    <td>
                      <button
                        className="btn-sm btn-primary"
                        onClick={() => this.handleFileDownload(val)}
                      >
                        Send To Email
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        )}
      </div>
    );
  }
}

export default ReportHistory;
