/**
 * React Core Exports
 */
import React, {useState, useEffect, useMemo, Fragment} from "react";


/**
 * Components That Complement CollectorTable3.js
 */
import DeviceTable3 from "./DeviceTable3";
import SiteDevicesTable from "./SiteDevicesTable";
import ModalEditCollector from "./ModalEditCollector";
import ModalSwitchCollector from "./ModalSwitchCollector";


/**
 * Exported External Libraries
 */
import {useTable, useExpanded, useSortBy} from "react-table";
import {
    Button,
    Table,
    Tooltip,
    OverlayTrigger,
    FormCheck,
} from "react-bootstrap";
import Fuse from "fuse.js";


/**
 * Internal Helpers
 */
import {Fetch} from "../../helpers/Fetch";
import {globalDateFormat} from "../../helpers/GlobalDateFormat";


/******************************************
 * Dayjs imports must be at the bottom of imports
 * because the .extend statements must come after all the imports.
 ******************************************/
import dayjs from "dayjs";

//import timezone from "dayjs/plugin/timezone";
//import advancedFormat from "dayjs/plugin/advancedFormat";
import utc from "dayjs/plugin/utc";
import LocalizedFormat from "dayjs/plugin/localizedFormat";

//dayjs.extend(timezone);
//dayjs.extend(advancedFormat);
dayjs.extend(utc);
dayjs.extend(LocalizedFormat);

let timer;
/********************************************
 * END OF DAYJS IMPORTS
 ********************************************/

/**
 * This is a Functional Component, not a Class Component
 * https://www.twilio.com/blog/react-choose-functional-components
 * @param props
 * @returns {JSX.Element}
 */
export default function CollectorTable3(props) {
    // console.log('CollectorTable3 props', props)

    const [collectors, setCollectors] = useState([]);
    const [filter_text, setFilter_text] = useState("");
    const [rowdata, setRowdata] = useState({});
    const [show_collector_modal, set_show_collector_modal] = useState(false);
    // const [show_switch_modal, set_show_switch_modal] = useState(false);
    const [show_all_devices, set_show_all_devices] = useState(false);
    const [all_device_data, set_all_device_data] = useState([]);
    const [ModalSwitchData, set_ModalSwitchData] = useState({
        avail_site_collectors: [], //List of available collectors on the site
        show_modal: false,  //Show the modal
        collector_id: null,  //collector_id of the child collector to be updated
        collector_name: null//collector_name of the child collector to be updated
    });

    useEffect(() => {
        fetch_site_collectors();
        fetch_all_devices();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    useEffect(() => {
        if (show_all_devices) {
            fetch_all_devices();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show_all_devices]);

    // show_collector_modal

    let DeviceTableRefresh = false;

    const fetch_site_collectors = function () {
        // let prev_site_id = sessionStorage.getItem("prev_site_id");
        Fetch("devices_api.php", {
            action: "get_site_collectors",
            site_id: sessionStorage.getItem("prev_site_id"),
        })
            .then((data) => {
                if (data && data.status === "ok") {
                    DeviceTableRefresh = true;
                    setCollectors(data.collectors);
                } else {
                    console.error("error", data);
                }
            })
            .catch((err) => console.error(err));
    };

    /**
     * Runs after mounting/rendering
     * and after a change of props
     */

    function handleKick(e) {
        //This "kicks" a collector to reset it in the field

        // console.log("kick_collector", e.target.attributes["cid"].value);
        Fetch("devices_api.php", {
            action: "kick_collector",
            collector_id: e.target.attributes["cid"].value,
        })
            .then(() => {
                fetch_site_collectors();
            })
            .catch((err) => console.log(err));
    }

    function handleAutoRefresh(e) {
        // console.log("handleAutoRefresh e", e.target.checked);
        // console.log('timer', typeof timer)


        if (e.target.checked && typeof timer !== 'number') {  //set timer
            // console.log('set timer')
            handleRefresh()  //refresh immediately
            timer = setInterval(() => {
                //If the devices tab is not active, clear timer
                // console.log("selected_menu_item.tab_id", sessionStorage.getItem("selected_menu_item.tab_id"))
                if (sessionStorage.getItem("selected_menu_item.tab_id").localeCompare( "devices")) {
                    // console.log('not visible - reset timer')
                    clearInterval(timer)
                    timer = undefined
                } else {
                    // console.log('timer refresh')
                    handleRefresh()  //refresh every ...
                }
            }, 5000);
        } else {
            //devices tab is not selected any longer - Kill the timer
            // console.log('not visible - reset timer')
            clearInterval(timer)
            timer = undefined
        }
    }

    function showAllDevices() {
        set_show_all_devices(!show_all_devices);
    }

    function fetch_all_devices() {
        Fetch("devices_api.php", {
            action: "get_devices",
            site_id: sessionStorage.getItem("prev_site_id"),
        })
            .then((data) => {
                if (data.status === "ok") {
                    set_all_device_data(data.devices);
                }
            })
            .catch((err) => console.log(err));
    }

    const handleRefresh = () => {
        if (show_all_devices) {
            Fetch("devices_api.php", {
                action: "get_devices",
                site_id: sessionStorage.getItem("prev_site_id"),
            })
                .then((data) => {
                    if (data.status === "ok") {
                        set_all_device_data(data.devices);
                    }
                })
                .catch((err) => console.log(err));
        } else {
            fetch_site_collectors();
        }
    };

    function handleSwitch(e) {
        // console.log("handleSwitch", e);
        // console.log("handleSwitch modify this cid", e.target.attributes["cid"].value);
        // console.log("handleSwitch modify this cid", e.target.attributes["c_name"].value);
        // console.log("handleSwitch existing parent cid", e.target.attributes["parent_cid"].value);

        Fetch("devices_api.php", {
            action: "get_avail_collectors",
            site_id: sessionStorage.getItem("prev_site_id"),  //From site selector
        })
            .then((data) => {
                if (data.status === "ok") {
                    // console.log('Get available collectors', data)

                    set_ModalSwitchData({
                        show_modal: true,
                        avail_site_collectors: data.collectors,
                        collector_id: e.target.attributes["cid"].value,  //child collector to change parent
                        collector_name: e.target.attributes["c_name"].value,
                        parent_collector_id: e.target.attributes["parent_cid"].value  //existing parent
                    })
                }
            })
            .catch((err) => console.log(err));

    }

    const fuse = new Fuse(show_all_devices ? all_device_data : collectors, {
        keys: [!show_all_devices ? "collector_name" : "device_name", "designation"],
        threshold: 0,
        ignoreLocation: true,
        isCaseSensitive: false, //false is the default, but I want this comment as a reminder
    });

    const search_results = fuse.search(filter_text);

    // console.log("Search Results", search_results, show_all_devices);

    const handle_filter_change = (e) => {
        setFilter_text(e.target.value);
    };

    const handle_clear_filter = () => {
        setFilter_text("");
        document.getElementById("device_filter_input").value = "";
    };

    const handleEditCollector = (e, props) => {
        setRowdata(props);
        set_show_collector_modal(true);
    };

    const handle_close_modal = () => {
        set_show_collector_modal(false);
        set_ModalSwitchData({avail_site_collectors: [], show_modal: false})

        handleRefresh()
    };

    const columns = useMemo(
        () => [
            {
                id: "TableHeader", //Unique ID for header
                hideColHeaders: true, //Hide the individual column headers for the columns below
                Header: () => {
                    //This is the main table header across the top of the table
                    return (
                        <div className="text-center">
                            <div style={{fontSize: "2em"}}>Metering Devices</div>
                            <div>
                                <Button className="btn-sm" onClick={handleRefresh}>
                                    Refresh
                                </Button>

                                <span className="ms-3">Show All Devices</span>
                                <FormCheck
                                    className="btn-sm"
                                    inline
                                    checked={show_all_devices}
                                    onChange={showAllDevices}
                                />

                                <>
                                    <span className="ms-3">Refresh every 5 seconds</span>
                                    <FormCheck
                                        className="btn-sm"
                                        inline
                                        onChange={handleAutoRefresh}
                                    />
                                    <input
                                        type="text"
                                        onChange={handle_filter_change}
                                        id="device_filter_input"
                                        placeholder={`Filter${
                                            show_all_devices
                                                ? "(Device N., Designation)"
                                                : "(Parent, Name)"
                                        }`}
                                        className="form-control-sm"
                                    />
                                </>

                                <button className="btn-sm ms-1" onClick={handle_clear_filter}>
                                    Clear
                                </button>
                            </div>
                        </div>
                    );
                },
                columns: [
                    {
                        // Make an expander cell
                        Header: () => null, // No header
                        id: "expander", // It needs an ID
                        Cell: ({row}) => {
                            // Use Cell to render an expander for each row.
                            // We use the getToggleRowExpandedProps prop-getter
                            // to build the expander.
                            // console.log("expand row", row);
                            // CAUTION: There is some react-table MAGIC that happens in this section

                            //console.log("row", row.original.parent_name);
                            if (row.original.parent_name) {
                                //If not an EDC/has a parent
                                if (row.isExpanded) {
                                    //if expanded
                                    return (
                                        <i
                                            {...row.getToggleRowExpandedProps()}
                                            className="far fa-minus-square"
                                            style={{color: "red"}}
                                        />
                                    );
                                } else {
                                    //if NOT expanded
                                    return (
                                        <i
                                            {...row.getToggleRowExpandedProps()}
                                            className="far fa-plus-square"
                                            style={{color: "green"}}
                                        />
                                    );
                                }
                            } else return null;
                        },
                    },
                    {
                        accessor: "parent_collector_id",
                    },
                    {
                        accessor: "collector_name",
                        Cell: (props) => {
                            return (
                                <div>
                                    <i
                                        className="fas fa-edit m-0"
                                        title="Click to edit configuration."
                                        onClick={(e) => handleEditCollector(e, props.row.original)}
                                    />
                                    {"Name: " + props.value}
                                </div>
                            );
                        },
                    },
                    {
                        accessor: "sub_device_type_name",
                    },
                    {
                        accessor: "gmt_last_contact",
                        Cell: (props) => {
                            const cellVal = props.cell.value;
                            //console.log("gmt_last_contact props", x, props);
                            return (
                                "Last: " +
                                (dayjs().unix() - dayjs.utc(cellVal).unix() < 3600 //if < 1 hour
                                    ? dayjs().unix() - dayjs.utc(cellVal).unix() + " sec ago" //show seconds
                                    : globalDateFormat(cellVal))
                            ); //show date
                        },
                    },
                    {
                        accessor: "parent_name",
                        c_className: "d-none d-lg-table-cell", //Hidden on smaller than lg
                        Cell: (props) => {
                            //console.log("parent_name props", props);
                            return props.cell.value ? (
                                <Fragment>
                                    <Button
                                        className="btn-sm pt-0 pb-0 me-1"
                                        cid={props.cell.row.original.collector_id}
                                        c_name={props.cell.row.original.collector_name}
                                        parent_cid={props.cell.row.original.parent_collector_id}
                                        onClick={handleSwitch}
                                    >
                                        Switch
                                    </Button>

                                    <span className="col">Parent: {props.cell.value}</span>
                                </Fragment>
                            ) : (
                                <span className="col">
                  Contact interval: {props.cell.row.original.contact_interval}
                                    &nbsp;sec
                </span>
                            );
                        },
                    },
                    {
                        id: "kick",
                        c_className: "pr-0 pl-0",
                        Cell: ({row}) => {
                            return (
                                <Button
                                    className="btn-sm float-end pt-0 pb-0 ms-2"
                                    cid={row.original.collector_id}
                                    onClick={handleKick}
                                >
                                    Kick
                                </Button>
                            );
                        },
                    },
                ],
            },
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [show_collector_modal, show_all_devices] //This [] must be here - The page will break otherwise
    );

    const memoCollectors = useMemo(
        () =>
            !show_all_devices && filter_text
                ? search_results.map((result) => result.item)
                : collectors,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [filter_text, collectors]
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        visibleColumns,
    } = useTable(
        {
            columns,
            data: memoCollectors,
            autoResetExpanded: false, //state.autoResetExpanded,
            initialState: {
                hiddenColumns: ["parent_collector_id"],
                sortBy: [
                    {
                        id: "parent_collector_id",
                        desc: false,
                    },
                    {
                        id: "collector_name",
                        desc: false,
                    },
                    {
                        id: "parent_name", //null (EDC units) float to the top
                        desc: false,
                    },
                    {
                        id: "sub_device_type_name",
                        desc: false,
                    },
                ],
            },
            // debugTable: true,
        },
        useSortBy,
        useExpanded
        // useGroupBy
    );

    let hideColHeaders = false;

    return (
        <>
            <Table //Bootstrap table settings
                // bordered
                size="sm"
                {...getTableProps()}
            >
                <thead>
                {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => {
                            //Check if we are to render headers
                            if (!hideColHeaders) {
                                //This will stop all future headers from being rendered
                                if (column.hideColHeaders)
                                    hideColHeaders = column.hideColHeaders;

                                return (
                                    <th
                                        {...column.getHeaderProps({
                                            className: column.c_className,
                                        })}
                                    >
                                        {column.render("Header")}
                                    </th>
                                );
                            }
                            return null;
                        })}
                    </tr>
                ))}
                </thead>
                {!show_all_devices && (
                    <tbody {...getTableBodyProps()}>
                    {/* Process collector rows */}
                    {rows.map((row) => {
                        prepareRow(row);
                        // console.log("prepared row", row);
                        const rd = row.original; //row data
                        return (
                            <Fragment key={"idx_" + row.id}>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip>
                                            CID: {rd.collector_id}
                                            <br/>
                                            Notes: {rd.collector_notes}
                                            <br/>
                                            Type: {rd.collector_type_name}
                                            <br/>
                                            Connect: {rd.connection}
                                            <br/>
                                            Alarm delay:&nbsp;{rd.alarm_delay_sec}&nbsp;seconds
                                            <br/>
                                            Config:&nbsp;
                                            {globalDateFormat(rd.gmt_last_config_update)}
                                        </Tooltip>
                                    }
                                    color="black"
                                >
                                    <tr
                                        {...row.getRowProps()}
                                        style={{
                                            backgroundColor: row.original.parent_name
                                                ? "salmon"
                                                : "orange",
                                        }}
                                    >
                                        {row.cells.map((cell) => {
                                            return (
                                                <td
                                                    {...cell.getCellProps({
                                                        className: cell.column.c_className,
                                                    })}
                                                >
                                                    {cell.column.c_onClickEdit ? (
                                                        <i
                                                            onClick={cell.column.c_onClickEdit}
                                                            rowdata={JSON.stringify(cell.row.original)}
                                                            className="fas fa-edit m-0"
                                                            title="Click to edit configuration."
                                                            style={{cursor: "pointer"}}
                                                        ></i>
                                                    ) : null}

                                                    {cell.render("Cell")}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                </OverlayTrigger>
                                {/*
                    If the row is in an expanded state, render a row with a
                    column that fills the entire length of the table.
                  */}
                                {row.isExpanded ? (
                                    <tr>
                                        <td colSpan={visibleColumns.length}>
                                            {/*
                      Inside the expanded row, call our SubComponent. In reality,
                      you could pass whatever you want as props to
                      a component like this, including the entire
                      table instance. But for this example, we'll just
                      pass the row data.

                      This is just a component like any other like a form or another table.
                    */}
                                            <DeviceTable3
                                                row={row.original} //Data for the expansion component
                                                scrollPosition={window.scrollY} //Keeps the page in approximately the same position
                                                refresh={DeviceTableRefresh} //Lets the child component know if it should refresh its data
                                            />
                                        </td>
                                    </tr>
                                ) : null}
                            </Fragment>
                        );
                    })}
                    </tbody>
                )}
            </Table>

            {show_all_devices && (
                <SiteDevicesTable
                    all_device_data={
                        filter_text
                            ? search_results.map((result) => result.item)
                            : all_device_data
                    }
                />
            )}
            <ModalEditCollector
                show_collector_modal={show_collector_modal}
                handle_close_modal={handle_close_modal}
                row_data={rowdata}
            />
            <ModalSwitchCollector
                ModalSwitchData={ModalSwitchData}
                handle_close_modal={handle_close_modal}
            />
        </>
    );
}
