import React, { Component } from "react";
import { Fetch } from "../../helpers/Fetch";
import { Button, Col, Form, Modal, Row, Alert } from "react-bootstrap";
import SimpleReactValidator from "simple-react-validator";
import { timezones } from "../../helpers/Timezones";

export default class AdminUserProfile extends Component {
  constructor(props) {
    super(props);

    //Initialize to stop extra rendering
    // this.stopRender = false;

    this.state = {
      prevent_collapse: false,
      user_profile: {},
      show_password_modal: false,
      password: "",
      all_site_list: [],
      locked_out: false,
      successfull_form_cancel: false,
      locked_out_db: false,
      show_alert: false,
      refresh_alert:false
    };
    this.validator = new SimpleReactValidator();
  }

  componentDidMount() {
    this.getUserData();
    // console.log(timezones);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.edited_user_id !== this.props.edited_user_id) {
      //New user selected
      // console.log("prevProps <> props = update user data");
      this.getUserData();
    }
  }

  getUserData() {
    //Not a good practise to declare variables with caps
    let Prom0 = Fetch("admin_profile.php", {
      action: "get_user_details",
      user_id: this.props.edited_user_id,
    }).then((data) => {
      return data.data;
    });

    let Prom1 = Fetch("admin_profile.php", {
      action: "get_site_list",
    }).then((data) => {
      return data.data;
    });

    //WAIT for all promises to complete before doing setState/Render
    Promise.all([Prom0, Prom1]).then((values) => {
      // console.log(values);
      this.setState({
        dataLoaded: true,
        user_profile: values[0], //Promise 0 result
        locked_out: values[0].locked_out,
        all_site_list: values[1], //Promise 1 result
      });
    });
  }

  handleLockedOut = () => {
    this.props.preventCollapse(true);
    const locked_out = !this.state.locked_out;
    this.setState({
      locked_out: locked_out,
      locked_out_db: locked_out ? "true" : "false",
      refresh_alert:true
    });


  };

  handleFormChange = (e) => {
    const { user_profile } = this.state;
    const { name, value } = e.target;

    this.props.preventCollapse(true);
    this.setState({
      form_changed: true,
      user_profile: {
        //Spread operator: Split to the form into separate state parameters
        ...user_profile,
        [name]: value,
      },
      refresh_alert:true
    });
  };

  handleFormCancel = (e) => {
    if (
      this.state.form_changed &&
      window.confirm("Form has been modified. Changes will be lost!")
    ) {
      this.getUserData(); //refresh the data from the API
      this.props.preventCollapse(false); //Allow collapse of the accordion
      //This will cause a render with fresh data from the server
      //Discarding any form changes.
      this.setState({
        dataLoaded: false, //Must load fresh data
        form_changed: false,
      });
    }
  };

  handlePasswordOpen = () => {
    this.setState({
      show_password_modal: true,
    });
  };

  handlePasswordClose = () => {
    //todo: Has the user entered a new password and not submitted
    this.setState({
      show_password_modal: false,
    });
  };
  handlePasswordChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  handleResetPassword = () => {
    const { password } = this.state;
    // console.log(this.props.edited_user_id);
    Fetch("admin_profile.php", {
      action: "reset_user_password",
      user_id: this.props.edited_user_id,
      password: password,
    })
      .then((data) => {
        this.setState({
          show_password_modal: false,
          set_password_success: true,
        });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  handleUpdateUserDetails = (e) => {
    const { user_profile, locked_out_db } = this.state;
    e.preventDefault();
    // console.log(user_profile);
    if (this.validator.allValid()) {
      Fetch("admin_profile.php", {
        action: "update_user_details",
        user_profile,
        locked_out_db,
      })
        .then((data) => {

          this.setState(
            {
              sys_users: data.data,
              show_success_modal: true,
            },
            () => this.props.preventCollapse(false)
          );
          if(this.state.refresh_alert ===true){
            window.location.reload();
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  handleAlertClose = () => {
    this.setState({
      successfull_form_cancel: false,
      show_success_modal: false,
    });
  };

  render() {
    const {
      dataLoaded,
      user_profile,
      show_password_modal,
      all_site_list,
      locked_out,
      successfull_form_cancel,
      show_success_modal
    } = this.state;
    // const { all_site_list } = this.props;

    if (!dataLoaded) {
      //No data available
      console.log("UserProfile render null");
      return null;
    } else {
      console.log("UserProfile render full state", this.state);

      return (
        <div style={{ pointerEvents: "auto" }}>
          {/* Tab content goes here */}
          {/*<p>PROPS:{JSON.stringify(this.props)}</p>*/}
          {/*<p>DATA:{JSON.stringify(user_profile)}</p>*/}

          {successfull_form_cancel && (
            <Alert variant="info" onClose={this.handleAlertClose} dismissible>
              Successfully Cancelled Form Input!
            </Alert>
          )}
          {show_success_modal && (
            <Alert
              variant="success"
              onClose={this.handleAlertClose}
              dismissible
            >
              Successfully updated User Form!
            </Alert>
          )}
          <Form>
            <Row className="mb-3">
              <Form.Group as={Col} id="admin_user_form">
                <Form.Label>User ID</Form.Label>
                <Form.Control
                  name="user_id"
                  onChange={this.handleFormChange}
                  value={user_profile && user_profile.user_id}
                  readOnly
                />
              </Form.Group>
              <Form.Group as={Col} controlId="username">
                <Form.Label>
                  Username -30{" "}
                  <i
                    className="fas fa-asterisk fa-xs"
                    style={{ color: "red" }}
                  />
                </Form.Label>
                <input
                  type="text"
                  className="form-control"
                  name="username"
                  value={user_profile.username ? user_profile.username : ""}
                  maxLength={30}
                  onChange={this.handleFormChange}
                />
                {this.validator.message(
                  "Username",
                  user_profile.username,
                  "max:30"
                )}
              </Form.Group>
              <Form.Group as={Col} controlId="role">
                <Form.Label>Role -10</Form.Label>
                <i className="fas fa-asterisk fa-xs" style={{ color: "red" }} />

                <input
                  type="text"
                  className="form-control"
                  name="user_role"
                  value={user_profile.user_role ? user_profile.user_role : ""}
                  maxLength={10}
                  onChange={this.handleFormChange}
                />
                {this.validator.message(
                  "User Role",
                  user_profile.user_role,
                  "required"
                )}
              </Form.Group>
              <Form.Group as={Col} controlId="formGridState">
                <Form.Label>User Site</Form.Label>
                <i className="fas fa-asterisk fa-xs" style={{ color: "red" }} />
                <select
                  className="form-control form-select"
                  onChange={this.handleFormChange}
                  placeholder={sessionStorage.getItem("site_name")}
                  value={user_profile && user_profile.site_id}
                  name="site_id"
                >
                  <option value="">Select A Site</option>
                  {all_site_list &&
                    all_site_list.map((val, index) => (
                      <option
                        key={val.site_id}
                        value={user_profile && val.site_id}
                      >
                        {val.site_name}
                      </option>
                    ))}
                </select>
              </Form.Group>
              <Form.Group as={Col} controlId="formGridState">
                <Form.Label>User TZ </Form.Label>
                <i className="fas fa-asterisk fa-xs" style={{ color: "red" }} />
                <select
                  name="user_tz"
                  value={user_profile.user_tz}
                  onChange={this.handleFormChange}
                  className="form-control form-select"
                >
                  <option value="">select a timezone</option>
                  {timezones.map((val, index) => (
                    <option value={val.name} key={index}>
                      {" "}
                      {val.name}
                    </option>
                  ))}
                </select>
              </Form.Group>
              <Form.Group
                as={Col}
                className="mt-4"
                controlId="formBasicCheckbox"
              >
                <Form.Check
                  type="checkbox"
                  label="Locked out"
                  name="locked_out"
                  checked={locked_out}
                  value={locked_out}
                  onChange={this.handleLockedOut}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="user_id">
                <Form.Label>First Name -15</Form.Label>
                <i className="fas fa-asterisk fa-xs" style={{ color: "red" }} />

                <input
                  type="text"
                  className="form-control"
                  name="fname"
                  value={user_profile.fname ? user_profile.fname : ""}
                  maxLength={15}
                  onChange={this.handleFormChange}
                />

                {this.validator.message(
                  "First Name",
                  user_profile.fname,
                  "max:15"
                )}
              </Form.Group>
              <Form.Group as={Col} controlId="username">
                <Form.Label>Last Name -15</Form.Label>
                <i className="fas fa-asterisk fa-xs" style={{ color: "red" }} />
                <input
                  type="text"
                  className="form-control"
                  name="lname"
                  value={user_profile.lname ? user_profile.lname : ""}
                  maxLength={15}
                  onChange={this.handleFormChange}
                />
                {this.validator.message(
                  "Last Name",
                  user_profile.lname,
                  "max:15"
                )}
              </Form.Group>
              <Form.Group as={Col} controlId="role">
                <Form.Label>Title</Form.Label>
                <Form.Control
                  name="user_title"
                  onChange={this.handleFormChange}
                  value={user_profile && user_profile.user_title}
                />
                {this.validator.message(
                  "User Title",
                  user_profile.user_title,
                  "max:30"
                )}
              </Form.Group>
              <Form.Group as={Col} controlId="role">
                <Form.Label>Phone -20</Form.Label>
                <input
                  type="text"
                  className="form-control"
                  name="phone_main"
                  value={user_profile.phone_main ? user_profile.phone_main : ""}
                  maxLength={20}
                  onChange={this.handleFormChange}
                />
                {this.validator.message(
                  "Phone Number",
                  user_profile.phone_main,
                  "max:20"
                )}
              </Form.Group>
              <Form.Group as={Col} controlId="role">
                <Form.Label>Email -128</Form.Label>
                <i className="fas fa-asterisk fa-xs" style={{ color: "red" }} />
                <input
                  type="text"
                  className="form-control"
                  name="email"
                  value={user_profile.email ? user_profile.email : ""}
                  maxLength={128}
                  onChange={this.handleFormChange}
                />
                {this.validator.message(
                  "User Email",
                  user_profile.email,
                  "max:128"
                )}
              </Form.Group>
            </Row>
            <hr />
            <Row className="mb-3">
              <Form.Group as={Col} controlId="user_id">
                <Form.Label>Company Name -30</Form.Label>
                <input
                  type="text"
                  className="form-control"
                  name="org_name"
                  value={user_profile.org_name ? user_profile.org_name : ""}
                  maxLength={30}
                  onChange={this.handleFormChange}
                />
                {this.validator.message(
                  "Organization Name",
                  user_profile.org_name,
                  "max:30"
                )}
              </Form.Group>
              <Form.Group as={Col} controlId="username">
                <Form.Label>Address Line 1 -30</Form.Label>
                <input
                  type="text"
                  className="form-control"
                  name="mail_address1"
                  value={
                    user_profile.mail_address1 ? user_profile.mail_address1 : ""
                  }
                  maxLength={30}
                  onChange={this.handleFormChange}
                />
                {this.validator.message(
                  "Mail address1",
                  user_profile.mail_address1,
                  "max:30"
                )}
              </Form.Group>
              <Form.Group as={Col} controlId="role">
                <Form.Label>Address Line 2 -30</Form.Label>
                <input
                  type="text"
                  className="form-control"
                  name="mail_address2"
                  value={
                    user_profile.mail_address2 ? user_profile.mail_address2 : ""
                  }
                  maxLength={30}
                  onChange={this.handleFormChange}
                />
                {this.validator.message(
                  "Mail address2",
                  user_profile.mail_address2,
                  "max:30"
                )}
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group className="col-8" controlId="mail_city">
                <Form.Label>City -30</Form.Label>
                <input
                  type="text"
                  className="form-control"
                  name="mail_city"
                  value={user_profile.mail_city ? user_profile.mail_city : ""}
                  maxLength={30}
                  onChange={this.handleFormChange}
                />
                {this.validator.message(
                  "Mail city",
                  user_profile.mail_city,
                  "max:30"
                )}
              </Form.Group>
              <Form.Group className="col-1" controlId="mail_state">
                <Form.Label>State -30</Form.Label>
                <input
                  type="text"
                  className="form-control"
                  name="mail_state"
                  value={user_profile.mail_state ? user_profile.mail_state : ""}
                  maxLength={30}
                  onChange={this.handleFormChange}
                />
                {this.validator.message(
                  "Mail state",
                  user_profile.mail_state,
                  "max:30"
                )}
              </Form.Group>
              <Form.Group className="col-3" controlId="mail_zip">
                <Form.Label>Zip -30</Form.Label>
                <input
                  type="text"
                  className="form-control"
                  name="mail_zip"
                  value={user_profile.mail_zip ? user_profile.mail_zip : ""}
                  maxLength={30}
                  onChange={this.handleFormChange}
                />
                {this.validator.message(
                  "Mail Zip",
                  user_profile.mail_zip,
                  "max:30"
                )}
              </Form.Group>
            </Row>
            <hr />

            <Button
              variant="primary"
              className=""
              onClick={this.handlePasswordOpen}
            >
              Set Password
            </Button>
            <span className="float-end">
              <Button
                variant="secondary"
                className="me-2"
                onClick={this.handleFormCancel}
              >
                Cancel
              </Button>
              <Button
                variant="danger"
                type="submit"
                onClick={this.handleUpdateUserDetails}
              >
                Submit
              </Button>
            </span>
          </Form>
          <Modal show={show_password_modal} onHide={this.handlePasswordClose}>
            <Modal.Header closeButton>
              <Modal.Title>User Update Password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <label>New Password</label>
              <input
                type="password"
                name="password"
                className="form-control"
                onChange={this.handlePasswordChange}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.handlePasswordClose}>
                Cancel
              </Button>
              <Button variant="primary" onClick={this.handleResetPassword}>
                Save Changes
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      );
    }
  }
}
