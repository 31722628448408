import React, {Component} from "react";
import {getContainerWidth} from "../helpers/getContainerWidth";

export default class AppFooter extends Component {
    constructor(props) {
        //console.log('constructor');
        super(props);
        this.state = {
            availWidth: 0,
        };
    }

    render() {
        let year = new Date().getFullYear();

        return (
            <footer
                key="AppFooter"
                className="page-footer font-small blue pt-1 pb-1 mt-3 d-print-none"
                style={{backgroundColor: "darkblue", color: "white"}}
            >
                <div className="footer-copyright text-center">
                    © {year} EnergyChaser, LLC
                </div>
                <div className="text-center d-block d-sm-none hidden-print">
                    XS Screen - Screen width: {getContainerWidth()}px
                </div>
                <div className="text-center d-none d-sm-block d-md-none hidden-print">
                    SM Screen - Screen width: {getContainerWidth()}px
                </div>
                <div className="text-center d-none d-md-block d-lg-none hidden-print">
                    MD Screen - Screen width: {getContainerWidth()}px
                </div>
                <div className="text-center d-none d-lg-block d-xl-none hidden-print">
                    LG Screen - Screen width: {getContainerWidth()}px
                </div>
                <div className="text-center d-none d-xl-block d-xxl-none hidden-print">
                    XL Screen - Screen width: {getContainerWidth()}px
                </div>
                <div className="text-center d-none d-xxl-block hidden-print">
                    XXL Screen - Screen width: {getContainerWidth()}px
                </div>
                <div className="text-center">React Version: {React.version}</div>
            </footer>
        );
    }
}
