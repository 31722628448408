import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { Col, Form, Row } from "react-bootstrap";
import { Fetch } from "../../helpers/Fetch";

export default function ModalEditCollector(props) {
    const [show_modal, set_show_modal] = useState(false);
    const {
        register,
        handleSubmit,
        reset,
    } = useForm();
    useEffect(() => {
        set_show_modal(props.show_collector_modal);
        get_edit_occ();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.show_collector_modal, reset]);

    const get_edit_occ = () => {
       reset(props.row_data)
    };

    const onSubmit = (data) => {
        /*Perform whatever code is required to submit */
        // console.log("form dirtyFields", dirtyFields, data);

        Fetch("TenantV3_api.php", {
            action: props.modal_type !=="Edit Tenant" ? "submit_new_tenant" :"editOccupantSubmit",
            dont_send_bill: data.dont_send_bill,
            energy_user_id: props.energy_user_id,
            email: data.email,
            fname: data.fname,
            lname: data.lname,
            no_end: data.no_end,
            org_name: data.org_name,
            phone_main: data.phone_main,
            square_ft: data.square_ft,
            cust_field2: data.t_number,
            occ_to_month: data.to_date,
            to_year_num: data.to_year,
            user_title: data.user_title,
            NO_occ_from_month: data.from_date,
            from_year_num: data.from_year,
            cust_field1: data.l_number,
            site_tz: "America/Los_Angeles",
            report_bill_id: props.report_bill_id,
        })
            .then((data) => {
                if (data && data.status === "ok") {
                    console.log("Fetch data.devices", data);
                    props.handle_close_modal();
                } else {
                    console.error("error", data);
                }
            })
            .catch((err) => console.error(err));
    };

    const handleCloseModal = () => {
        props.handle_close_modal();
    };

    return (
        <Modal
            show={show_modal} //this can be true because we are checking for data above
            // onHide={() => setData(null)} //When hiding, clear out data
            backdrop="static" //User must press cancel or submit to hide modal
            keyboard={false} //User must press cancel or submit to hide modal
            centered //centered on screen vertically
            size="lg" //Small modal
        >
            <Modal.Header className="text-center">
                <Modal.Title> Edit Collector</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group
                        as={Row}
                        className="justify-content-center align-content-center mt-3"
                    >
                        <Form.Label column sm={3} style={{ whiteSpace: "nowrap" }}>
                            Name
                        </Form.Label>
                        <Col sm={6}>
                            <Form.Control size="sm" type="text" {...register("collector_name")} />
                        </Col>

                    </Form.Group>
                    <Form.Group
                        as={Row}
                        className="justify-content-center align-content-center mt-3"
                    >
                        <Form.Label column sm={3} style={{ whiteSpace: "nowrap" }}>
                            Contact Interval
                        </Form.Label>
                        <Col sm={6}>
                            <Form.Control size="sm" type="text" {...register("contact_interval")} />
                        </Col>
                    </Form.Group>
                    <Form.Group
                        as={Row}
                        className="justify-content-center align-content-center mt-3"
                    >
                        <Form.Label column sm={3} style={{ whiteSpace: "nowrap" }}>
                            Parent
                        </Form.Label>
                        <Col sm={6}>
                            <Form.Control size="sm" type="text" {...register("parent_name")} />
                        </Col>
                    </Form.Group>
                    <hr/>
                    <div className="float-end">
                        <Button className="btn-sm btn-secondary" onClick={handleCloseModal}>
                            Cancel
                        </Button>
                        <Button type="submit" className="mx-2 btn-sm btn-primary">
                            Submit
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
}
