import React from "react";
import { Link } from "react-router-dom";
import { history } from "../helpers/history";
import { Alert } from "react-bootstrap";
import { svr_name } from "../helpers/AppConstants";
import { LogUserAction } from "../helpers/LogUserAction";

export class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        username: "",
        password: "",
      },
      login_failure: '',
      signing_in: false,
    };
  }

  componentDidMount() {
    let auth = localStorage.getItem("login_state");
    if (auth) {
      history.push("/");
    }
  }

  onFormItemChanged = (e) => {
    const { name, value } = e.target;
    const { form } = this.state;
    this.setState({
      form: {
        ...form,
        [name]: value,
      },
    });
  };

  userLogin = (e) => {
    this.setState({
      signing_in: true,
    });
    let w = window,
      d = document,
      el = d.documentElement,
      g = d.getElementsByTagName("body")[0],
      x = w.innerWidth || el.clientWidth || g.clientWidth,
      y = w.innerHeight || el.clientHeight || g.clientHeight;
    console.log("logging in");
    e.preventDefault();
    const { form } = this.state;
    fetch(svr_name + "login.php", {
      method: "POST",
      mode: "cors",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        inputUsername: form.username,
        inputPassword: form.password,
        screen_width: x,
        screen_height: y,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.loginStatus === "good") {
          LogUserAction("Successful login by username: " + form.username);
          localStorage.setItem("login_data", JSON.stringify(data));
          localStorage.setItem("user_menu", JSON.stringify(data.user_menu));
          localStorage.setItem("login_state", true);
          history.push("/");
          setTimeout(window.location.reload(), 300);
        } else {
          LogUserAction("FAILED login by username: " + form.username);
          this.setState({
            login_failure: 'Incorrect Username/Password',
            signing_in: false,
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);

        let errorMessage = error.message
        if(error.message === 'Failed to fetch') errorMessage = 'Server not available';

        this.setState({
          login_failure: errorMessage,
          signing_in: false,
        });
      });
  };

  render() {
    const { form, login_failure, signing_in } = this.state;
    return (
      <div className="signin-container">
        <div className="signin-header">
          <img
            //src="https://data.energychaser.com/img/ec_logo.gif"
            src="img/ec_logo.gif"
            className="landing-logo"
            alt="logo"
          />
        </div>
        <form onSubmit={this.props.handleUserLogin} className="signin-form">
          <h3 className="form-header">Sign in to your EnergyChaser Account</h3>
          <div className="form-header-description">
            Don't have an account? <Link to={`/register`}>Register</Link>
          </div>
          {!!login_failure && (
            <Alert className="text-center" variant="danger">
              {login_failure}
            </Alert>
          )}

          <label className="form-label">Username</label>
          <input
            type="text"
            placeholder="e.g johndoe"
            className="form-control"
            value={form.username}
            name="username"
            onChange={(event) => this.onFormItemChanged(event)}
          />
          <label className="form-label">Password</label>
          <input
            type="password"
            className="form-control"
            value={form.password}
            name="password"
            onChange={(event) => this.onFormItemChanged(event)}
          />
          <button
            className="btn btn-primary login-btn"
            onClick={this.userLogin}
            disabled={signing_in}
          >
            Sign in
          </button>
        </form>
      </div>
    );
  }
}
