export function getContainerWidth() {

    const e = document;
    return Math.max(
        e.body.scrollWidth,
        e.documentElement.scrollWidth,
        e.body.offsetWidth,
        e.documentElement.offsetWidth,
        e.documentElement.clientWidth
    )
}
