import { PureComponent } from "react";

import Navigation from "../Navbar";
import AppFooter from "../AppFooter";
import NonElecTable from "./NonElecTable";
import SearchBar from "./SearchBar";
import SiteSelect from "../Global/Site_select2";
import { Fetch } from "../../helpers/Fetch";

/*
        dayjs - momentjs replacement
        This block of code must be at the end of the imports
        because the last two lines must follow all imports.
 */
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

class NonElectric extends PureComponent {
  constructor(props) {
    super(props);

    //console.log('Nonelec constructor props', this.props);

    // this.date = dayjs() //Default start date
    //     .startOf("month")
    //     .subtract(5, "months");

    this.panned_date = 0;

    this.date = dayjs() //Default start date
      .startOf("month")
      .subtract(5, "months");

    this.state = {
      site_id: sessionStorage.getItem("prev_site_id"),
      filterText: "",
      dates: [],
      non_elec_data: [],
      panned_val: 0,
      date: dayjs().startOf("month").subtract(5, "months"),
    };
  }

  componentDidMount() {
    // console.log("componentDidMount");
    let site_id = sessionStorage.getItem("prev_site_id");
    if (site_id) {
      this.getNonElectricData(site_id);
    }
  }

  /******************************************************************************
   *    Page event scripts
   ******************************************************************************/

  westfieldExport = (e) => {
    e.preventDefault();

    import(
      /* webpackChunkName: "ExcelExportNonElect" */ "../../helpers/ExcelExport"
    ).then((module) => {
      // console.log("ExcelExport chunk import done");
      Fetch("nonElec_api.php", {
        action: "westfield_export",
      }).then((data) => {
        if (data.status === "ok") {
          console.log("Westfield data", data.data);
        }
      });
    });
  };

  excelExport = (e) => {
    e.preventDefault();

    import(
      /* webpackChunkName: "ExcelExportNonElectric2" */ "../../helpers/ExcelExport"
    ).then((module) => {
      // console.log("ExcelExport chunk import done");
      const { non_elec_data, dates } = this.state;

      //Get the raw data from storage
      // const x = sessionStorage.getItem("excelExport");
      // const data = JSON.parse(x);
      // const d = sessionStorage.getItem("excelExportDates");
      // const dates = JSON.parse(d);

      //console.log("excelExport data", dates, data);

      //Process the rows into the excel export object
      let output = [
        [
          "DID",
          "Device Name",
          "Units",
          dates[0],
          dates[1],
          dates[2],
          dates[3],
          dates[4],
          dates[5],
        ],
      ];

      // let UnitConvert; //1 HCF = 748 gallons   12000 BTU = 1 ton
      non_elec_data.forEach((r, i) => {
        //console.log(r);
        let UnitConvert = r.param_id === 24 ? 1 / 748 : 1 / 12000;
        let row = [
          r.device_id,
          r.device_name,
          r.param_id === 24 ? "HCF Water" : "Tons CW",
          (r.param_id === 24
            ? Number(r.p1)
            : // + Number(r.p1_end_correction)
              Number(r.p1)) * UnitConvert,
          (r.param_id === 24
            ? Number(r.p2)
            : // +
              // Number(r.p2_end_correction) -
              // Number(r.p1_end_correction)
              Number(r.p2)) * UnitConvert,
          (r.param_id === 24
            ? Number(r.p3)
            : // +
              // Number(r.p3_end_correction) -
              // Number(r.p2_end_correction)
              Number(r.p3)) * UnitConvert,
          (r.param_id === 24
            ? Number(r.p4)
            : // +
              // Number(r.p4_end_correction) -
              // Number(r.p3_end_correction)
              Number(r.p4)) * UnitConvert,
          (r.param_id === 24
            ? Number(r.p5)
            : // +
              // Number(r.p5_end_correction) -
              // Number(r.p4_end_correction)
              Number(r.p5)) * UnitConvert,
          (r.param_id === 24
            ? Number(r.p6)
            : // +
              // Number(r.p6_end_correction) -
              // Number(r.p5_end_correction)
              Number(r.p6)) * UnitConvert,
        ];
        output.push(row);
      });

      // console.log("output", output);

      //Build this object to pass to excelExport
      const Excel = {
        template: "nonElectExportV2.xlsx", //Excel template
        Worksheets: [
          {
            worksheet: "Sheet1", //Worksheet in template
            start_row: 3, //Starting at 1
            start_col_num: 1, //Starting at A=1, B=2
            rows: output,
          },
        ],
        output_filename: "export.xlsx", //Default filename of file
      };

      //console.log("export", Excel);
      module.excelExport(Excel);
    });
  };

  //Called from global component Site_select
  handleSiteChange = (e) => {
    console.log("handleSiteChange e", e);
    sessionStorage.setItem("prev_site_id", e.site_id);
    sessionStorage.setItem("prev_site_tz", e.site_tz);
    sessionStorage.setItem("o_value", e.o_value);
    sessionStorage.setItem("prev_site_name", e.label);

    this.setState(
      {
        site_id: e.value,
        site_tz: e.site_tz,
      },
      () => this.getNonElectricData(e.value)
    );
  };

  handleFilterTextChange = (filterText) => {
    this.setState({
      filterText: filterText,
    });
  };

  handleTableReload = () => {
    const { site_id } = this.state;
    this.getNonElectricData(site_id);
  };

  handlePanningChange = (e, val) => {
    const { date, site_id, panned_val } = this.state;
    console.log(val);
    if (Number.isInteger(val)) {
      this.setState(
        {
          panned_val: panned_val + val,
          date: dayjs(date).startOf("month").add(val, "months"),
        },
        () => this.getNonElectricData(site_id)
      );
    } else if (val === "current") {
      this.setState(
        {
          panned_val: 0,
          date: dayjs().startOf("month").subtract(5, "months"),
        },
        () => this.getNonElectricData(site_id)
      );
    }
  };

  /******************************************************************************
   *    Fetch data
   ******************************************************************************/
  getNonElectricData = (site_id) => {
    const { date } = this.state;
    Fetch("nonElec_api.php", {
      action: "fill_table",
      startDate: date, //ideal way as ISO string
      showDisabled: false,
      site_id: site_id,
    }).then((data) => {
      if (data.status === "ok") {
        this.setState({
          non_elec_data: data.data,
          dates: data.dates,
        });
      } else {
        console.log("Fetch error", data);
      }
    });
  };

  render() {
    // console.log("Nonelec render");
    const { non_elec_data, filterText, panned_val, dates, site_tz } =
      this.state;

    return (
      <div>
        <Navigation />
        <div className="container-fluid main-container-div">
          <div className="sticky-top sticky-site-selector" style={{backgroundColor:"white"}}>
            <div className="col-4 offset-4  " align="center">
              <SiteSelect handleSiteChange={this.handleSiteChange} />
            </div>
          </div>
          {non_elec_data && (
            <div className="button-bar sticky-cc-bar " style={{backgroundColor:"white"}}>
              <div className="container">
                <div className="row mb-2  d-print-none ">
                  <div className="col mt-1">
                    <button
                      className="btn btn-primary btn-sm button-bar-button mx-2 "
                      onClick={this.handleTableReload}
                    >
                      Refresh Table
                    </button>
                    <label className="me-1 mx-1"> Show disabled </label>
                    <input type="checkbox" name="disabled" value="disabled" />
                    <i
                      className="fas fa-chart-bar"
                      style={{ color: "black", marginLeft: "10px" }}
                    />
                    &nbsp;Click for more information
                  </div>
                  <div className="col mt-2">
                    <button
                      className="btn btn-primary btn-sm button-bar-button me-1"
                      onClick={(e) => this.handlePanningChange(e, -3)}
                    >
                      <span className="fas fa-chevron-double-left" />
                    </button>
                    <span className="button-bar-button me-1">3 months</span>
                    <button
                      className="btn btn-primary btn-sm button-bar-button me-1"
                      onClick={(e) => this.handlePanningChange(e, 3)}
                    >
                      <span className="fas fa-chevron-double-right" />
                    </button>
                    <button
                      className="btn btn-primary btn-sm button-bar-button"
                      onClick={(e) => this.handlePanningChange(e, "current")}
                    >
                      Current
                    </button>
                    <button
                      className="btn btn-secondary btn-sm mx-1 "
                      onClick={this.excelExport}
                    >
                      Excel
                    </button>
                    <button
                      className="btn btn-secondary btn-sm mx-1 "
                      onClick={this.westfieldExport}
                    >
                      Westfield
                    </button>
                  </div>
                  <div className="col-2 mt-2">
                    <SearchBar
                      filterText={filterText}
                      onFilterTextChange={this.handleFilterTextChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          {non_elec_data && (
            <NonElecTable
              non_elec_data={non_elec_data}
              filterText={filterText}
              panned_date={panned_val}
              dates={dates}
              site_tz={site_tz}
              // date={date}
            />
          )}
        </div>
        {/* Standard application footer for all pages */}
        <AppFooter />
      </div>
    );
  }
}

export default NonElectric;
