import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

/**
 *
 * @param date The date you want formatted
 * @param tz The time zone to format the date. Defaults to browsers TZ
 * @param format The format of the date string. Defaults to "L HH:mm:ss z"
 * @returns {string}
 * Simplest use: globalDateFormat(some_date)  --80% of use
 * Next level: globalDateFormat(some_date, site_tz or logged user TZ) --20% of use
 * Advanced: globalDateFormat(some_date, site_tz, 'L') --only display date --used rarely
 */
export function globalDateFormat(date, format, tz) {
  //If TZ not specified, default to browser TZ
  if (typeof tz === "undefined") {
    tz = Intl.DateTimeFormat().resolvedOptions().timeZone; //default to browsers TZ
    //console.log("Undefined TZ provided: TZ set to browser tz", tz);
  }
  //If format not specified, default to "m/d/yyyy h:m:s AM/PM TZ"
  if (typeof format === "undefined") {
    const newDate = new Intl.DateTimeFormat('en', {
      timeZone: tz,
      timeZoneName:'short',
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second:'numeric'
    }).format(new Date(date))

    return newDate.replaceAll(',','')
  }

  return dayjs.utc(date).tz(tz).format(format);
}
