import { Component } from "react";
import Select from "react-select";
import { Fetch } from "../../helpers/Fetch";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

class SiteSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterText: "",
      prev_site_id: sessionStorage.getItem("prev_site_id"),
      prev_site_name: sessionStorage.getItem("prev_site_name"),
      prev_site_tz: sessionStorage.getItem("prev_site_tz"),
      prev_site_notes: sessionStorage.getItem("prev_site_notes"),
      site_options:[]
    };
    console.log("SiteSelect state", this.state);
  }
  componentDidMount() {
    this.getSiteData(); //Get from server
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.site_notes !== this.props.site_notes) {
      this.setState({prev_site_notes: this.props.site_notes})
      sessionStorage.setItem("prev_site_notes", this.props.site_notes)
    }
  }

  handleChange(e) {
    if(sessionStorage.getItem("prev_site_name") !== e.label) {
      sessionStorage.removeItem("prev_period_label");
      sessionStorage.removeItem("prev_reid_select");
    }

    this.updateValues(e)
  }

  updateValues(e){
    console.log("updateValues e", e);
    sessionStorage.setItem("prev_site_id", e.site_id);
    sessionStorage.setItem("prev_rate_id", e.rate_id);
    sessionStorage.setItem("prev_site_tz", e.site_tz);
    sessionStorage.setItem("prev_site_name", e.value);
    sessionStorage.setItem("prev_site_notes", e.site_notes)

    //Causes render
    this.setState({
      prev_site_id: e.site_id,
      prev_site_name: e.label,
      prev_site_tz: e.site_tz,
      prev_site_notes: e.site_notes})

  }

  render() {
    // console.log('SiteSelect2 render *****', this.state)
    const {prev_site_id, prev_site_name, prev_site_tz, prev_site_notes, site_options} = this.state

    if (site_options.length === 0) return null;

    return (
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip className='lh-sm' style={{maxWidth:'700px'}}>
            <div>Site ID: {prev_site_id}</div>
            <div>Site Time Zone: {prev_site_tz}</div>
            {prev_site_notes !== 'null' && <div>Site Notes: {prev_site_notes}</div>}
          </Tooltip>
        }
        color="black"
      >
        <div>
          <Select
              blurInputOnSelect={true}
              options={site_options.sort((a, b) => (a.label > b.label ? 1 : -1))}
              className="mt-2 mb-2"
              //Use the change script from the caller page
              onChange={(e)=> {
                this.handleChange(e)
                this.props.handleSiteChange(e)
              }}
              value={{ label: prev_site_name, value: prev_site_id }}
              placeholder="Select a Site"
              isDisabled={this.props.isDisabled || site_options.length <= 1}
          />
        </div>
      </OverlayTrigger>
    );
  }

  getSiteData() {
    Fetch("functions_api.php", {
      action: "pop_selectSite",
      obj_id: "selectSite",
    }).then((data) => {
      if (data.status === "ok") {
        //Build the selection options
        const options = data.data.map((val) => ({
          label: val.o_text,
          value: val.o_text,
          site_tz: val.site_tz,
          rate_id: val.o_value,
          site_id: val.site_id,
          site_notes: val.site_notes,
        }));

        this.setState({site_options:options})

        //declare data length here use bool send in props
        if (data.data.length === 1) {
          this.updateValues(data.data[0])
        }
      } else {
        console.log("Fetch ERROR", data);
      }
    });
  }
}

export default SiteSelect;
