/*
This are the available timezones on energychaser
 */

export const timezones = [
    {name:"Africa/Nairobi"},
    {name:"America/New_York"},
    {name:"America/Chicago"},
    {name:"America/Denver"},
    {name:"America/Los_Angeles"}
]