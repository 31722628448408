let URL_svr_name = "";

const subdomain = window.location.hostname.split('.').slice(0, -2).join('.');

switch (subdomain) {
    case "data":  //Production
        URL_svr_name = "https://data.energychaser.com/api_react/";
        break;
    case "data2":  //Testing
        URL_svr_name = "https://data2.energychaser.com/api_react/";
        break;
    case "data4":  //Development
        URL_svr_name = "https://data4.energychaser.com/api_react/";
        break;
    case "portal":  //Development
        URL_svr_name = "https://portal.energychaser.com/api_react/";
        break;
    default:
        if (window.location.hostname === 'localhost')
            URL_svr_name = "https://data2.energychaser.com/api_react/";
        else
            alert("Unknown host/server name " + subdomain + window.location.hostname + "!\nFile: AppConstants.js")
}
export const svr_name = URL_svr_name;
