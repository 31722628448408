import React, { Component } from "react";
import Navigation from "../Navbar";
import { Fetch } from "../../helpers/Fetch";
import Dropzone from "react-dropzone";
import SiteSelect from "../Global/Site_select2";
import {
  Button,
  Card,
  Col,
  Modal,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip,
} from "react-bootstrap";
// import { file_upload } from "../../helpers/file_upload";
import { svr_name } from "../../helpers/AppConstants";
import Compressor from "compressorjs";
import "./Documentation.css";

import dayjs from "dayjs";

import { NumberFormat } from "../../helpers/NumberFormat";
import Fuse from "fuse.js";
import GroupSelect from "../Global/GroupSelect";
import {SpinnerOn} from "../../helpers/SpinnerOn";
import {SpinnerOff} from "../../helpers/SpinnerOff";

class Documentation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      site_tz: sessionStorage.getItem("prev_site_tz"),
      site_id: sessionStorage.getItem("prev_site_id"),
      group_data: [],
      report_group_id: "",
      report_group_name: "",
      files: [],
      show_drop_zone: false,
      processed_data: [],
      summary: "",
      description: "",
      results: [],
      docu_files: [],
      show_edit_docu_modal: false,
      show_delete_docu_modal: false,
      edit_image_url: "",
      s3_filename: "",
      show_spinner: false,
      filter_text: "",
      show_group_select: false,
      file_size_alert:false
      // now_iso:dayjs.utc().toISOString(),
      // utc_date:dayjs().format("MM-DD-YYYY HH:mm")
    };

    if (this.state.site_id) {
      this.get_all_groups(this.state.site_id);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.files !== this.state.files) {
      if(this.state.files[0]){
        if(this.state.files[0].size > 80000000){
          this.setState({
            file_size_alert:true,
            files:[]
          })
        }

      }

    }
  }

  handleCloseFileSizeAlert = () =>{
    this.setState({
      file_size_alert:false
    })
  }

  get_docu_files = () => {
    const { report_group_id } = this.state;
    Fetch(`documentation_api.php`, {
      action: "get_documentation_files",
      report_group_id: report_group_id,
    })
      .then((data) => {
        if (data.status === "ok") {
          this.setState({
            docu_files: data.files,
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  get_all_groups = (site_id) => {
    Fetch(`documentation_api.php`, {
      action: "fill_groups_dropdown",
      site_id: site_id,
    })
      .then((data) => {
        if (data && data.data.length === 1) {
          this.setState(
            {
              group_data: data.data,
              report_group_id: data.data[0].report_group_id,
              report_group_name: data.data[0].report_group_name,
            },
            () => this.get_docu_files()
          );
        } else {
          this.setState({
            group_data: data.data,
            report_group_id: "",
            report_group_name: "",
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  handleSiteChange = (e) => {
    this.setState({
      site_tz: e.site_tz,
      site_id: e.site_id,
      show_group_select: true,
    });
    sessionStorage.removeItem("prev_group_id");
    sessionStorage.removeItem("prev_group_name");
    this.get_all_groups(e.site_id);
  };

  handleCloseModal = () => {
    this.setState({
      show_drop_zone: false,
      files: [],
    });
  };

  handleCloseEditModal = () => {
    this.setState({
      show_edit_docu_modal: false,
    });
  };

  handleCloseDeleteModal = () => {
    this.setState({
      show_delete_docu_modal: false,
    });
  };

  handleshowEditModal = (val) => {
    this.setState({
      show_edit_docu_modal: true,
      summary: val.file_summary,
      description: val.file_description,
      edit_image_url: val.presignedUrl,
      s3_filename: val.s3_filename,
    });
  };

  handleshowDeleteModal = (val) => {
    this.setState({
      show_delete_docu_modal: true,
      s3_filename: val.s3_filename,
    });
  };

  handleShowModal = () => {
    this.setState({
      show_drop_zone: true,
      files:[]
    });
  };

  handleFormChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  handleSaveThumb = (now_iso) => {
    const { files, site_id, report_group_id } = this.state;
    if (files[0] && files[0].type.includes("image")) {
      new Compressor(files[0], {
        quality: 2,
        height: 200,
        retainExif: true, //This works great
        success(result) {
          let form_data = new FormData();
          form_data.append("tab_id", "documentation_thumbnail_upload");
          form_data.append("action", "file_upload");
          form_data.append("now_iso", now_iso);
          form_data.append("site_id", site_id);
          form_data.append("report_group_id", report_group_id);
          form_data.append("thumb", result); //Attach file - required
          form_data.append("file_mime_type", files[0].type);
          form_data.append("original_filename", files[0].name);
          fetch(svr_name + "functions_api.php", {
            method: "POST",
            credentials: "include",
            mode: "cors",
            body: form_data,
          })
            .then((response) => response.json())
            .then((data) => {
            })
            .catch((error) => {
              console.error("Error:", error);
              return error;
            });
        },
      });
    }
  };

  handleSaveUpload = () => {
    const { summary, description, files, site_id, report_group_id } =
      this.state;

    SpinnerOn()

    this.setState({
      show_spinner: true,
    });

    // console.log(new Date().toISOString())

    let form_data = new FormData();
    let now_iso = new Date().toISOString();
    let utc_date = dayjs().format("MM-DD-YYYY HH:mm:ss");

    // console.log(now_iso,"Handle Save Called");

    form_data.append("tab_id", "documentation_file_upload");
    form_data.append("now_iso", now_iso);
    form_data.append("action", "file_upload");
    form_data.append("file_summary", summary);
    form_data.append("site_id", site_id);
    form_data.append("report_group_id", report_group_id);
    form_data.append("file_description", description);
    form_data.append("file", files[0]); //Attach file - required
    form_data.append("file_mime_type", files[0].type);
    form_data.append("original_filename", files[0].name);
    form_data.append("file_size_kb", Math.trunc(files[0].size / 1024));
    form_data.append("utc_document_date", utc_date);

    fetch(svr_name + "functions_api.php", {
      method: "POST",
      credentials: "include",
      mode: "cors",
      body: form_data,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "ok") {
          SpinnerOff()
          this.setState(
            {
              show_drop_zone: false,
              show_spinner: false,
            },
            () => this.handleSaveThumb(now_iso)
          );
          this.get_all_groups(site_id);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        return error;
      });
  };

  handleSaveEdit = () => {
    const { summary, description, site_id, report_group_id, s3_filename } =
      this.state;
    Fetch(`documentation_api.php`, {
      action: "update_documentation",
      report_group_id: report_group_id,
      site_id: site_id,
      s3_filename: s3_filename,
      file_summary: summary,
      file_description: description,
    })
      .then((data) => {
        if (data.status === "ok") {
          this.setState(
            {
              show_edit_docu_modal: false,
            },
            () => this.get_all_groups(site_id)
          );
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  handleSaveDelete = () => {
    const { site_id, report_group_id, s3_filename } = this.state;
    Fetch(`documentation_api.php`, {
      action: "delete_entry",
      report_group_id: report_group_id,
      site_id: site_id,
      s3_filename: s3_filename,
    })
      .then((data) => {
        if (data.status === "ok") {
          this.setState(
            {
              show_delete_docu_modal: false,
            },
            () => this.get_all_groups(site_id)
          );
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  handleGroupSelect = (e) => {
    sessionStorage.setItem("prev_group_id", e.value);
    sessionStorage.setItem("prev_group_name", e.label);
    this.setState(
      {
        report_group_id: e.value,
        report_group_name: e.label,
      },
      () => this.get_docu_files()
    );
  };

  handleFileDownload = (value) => {
    Fetch(`documentation_api.php`, {
      action: "download_documentation_file",
      s3_filename: value.s3_filename,
      original_filename: value.original_filename,
    })
      .then((data) => {
        console.log(data);
        if (data.status === "ok") {
          let uri = data.file.presignedUrl;
          const link = document.createElement("a");
          // link.download = name;
          link.href = uri;
          link.setAttribute("download", value.original_filename);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  handle_filter_change = (e) => {
    // console.log(e.target.value);
    this.setState({ filter_text: e.target.value });
  };

  handle_clear_filter = () => {
    this.setState({ filter_text: "" });
  };

  render() {
    const {
      group_data,
      show_drop_zone,
      files,
      docu_files,
      show_edit_docu_modal,
      summary,
      description,
      edit_image_url,
      show_delete_docu_modal,
      show_spinner,
      filter_text,
      site_id,
      file_size_alert
    } = this.state;

    const fuse = new Fuse(docu_files, {
      keys: ["file_summary"],
      threshold: 0,
      ignoreLocation: true,
      isCaseSensitive: false, //false is the default, but I want this comment as a reminder
    });

    const search_results = fuse.search(filter_text);

    let docu_files_final = filter_text
      ? search_results.map((result) => result.item)
      : docu_files;

    this.onDrop = (files) => {
      // this.setState({ files: [...this.state.files, ...files] });
      this.setState({ files });
    };

    const handle_file_delete = (index) => {
      files.splice(index, 1);
      this.setState({ files: files, processed_data: [] });
    };

    const dropped_files = files.map((file, index) => (
      <li
        style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          listStyle: "none",
        }}
        key={index}
      >
        <i
          className="fa fa-trash me-2"
          style={{ color: "red" }}
          onClick={(e) => handle_file_delete(index)}
          aria-hidden="true"
        />
        {file.name} - {Math.trunc(file.size / 1024)}Kb
      </li>
    ));

    return (
      <div>
        <Navigation />

        <div className="row">
          <div className="col-lg-4 col-xs-12 offset-lg-3">
            <SiteSelect handleSiteChange={this.handleSiteChange} />
            {site_id &&
              <GroupSelect
                handleGroupSelect={this.handleGroupSelect}
                site_id={site_id}
              />
            }

          </div>
          <div className="col-1 mt-3">
            {group_data.length > 0 && (
              <button
                className="btn btn-primary btn-sm"
                onClick={this.handleShowModal}
              >
                New Entry
              </button>
            )}
          </div>
          {group_data.length > 0 && (
            <div className="col-2 mt-3">
              <input
                type="text"
                onChange={this.handle_filter_change}
                value={filter_text}
                id="device_filter_input"
                placeholder="Filter(Summary)"
                className="form-control-sm"
              />

              <button className="btn-sm" onClick={this.handle_clear_filter}>
                Clear
              </button>
            </div>
          )}
        </div>

        <div>
          <Modal
            show={show_drop_zone}
            onHide={this.handleCloseModal}
            keyboard="true"
            size="xl"
          >
            <Modal.Header className="justify-content-center">
              <Modal.Title>New Documentation Entry</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {show_drop_zone && (
                <span className="documentationStyleDropZone">
                  {/*100MB max file drop*/}
                  <Dropzone maxSize={80000000} onDrop={this.onDrop}>
                    {({ getRootProps, getInputProps }) => (
                      <div {...getRootProps({ className: "dropzone" })}>
                        <input {...getInputProps()} />
                        <p className="drop-zone-text">
                          Drag and drop a file. 80MB Max.
                        </p>
                      </div>
                    )}
                  </Dropzone>
                  <div className="text-center">
                {files.length > 0 && (
                    <aside
                        style={{
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                        }}
                    >
                      <h4>Files</h4>
                      <ul>{dropped_files}</ul>
                    </aside>
                )}
              </div>
                </span>
              )}

              {show_spinner && (
                <div>
                  <div className="text-center">
                    <span>Loading...</span>
                    <Spinner
                      className="text-center"
                      animation="border"
                      role="status"
                    ></Spinner>
                  </div>
                </div>
              )}
              <div>
                <label className="form-label">Summary</label>
                <input
                  className="form-control"
                  name="summary"
                  placeholder="max length 100 characters"
                  maxLength="100"
                  onChange={this.handleFormChange}
                />
              </div>
              <div>
                <label className="form-label">Description</label>
                <textarea
                  className="form-control"
                  name="description"
                  placeholder="max length 250 characters"
                  maxLength="250"
                  onChange={this.handleFormChange}
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                id="payment-button-cancel"
                disabled={show_spinner}
                onClick={this.handleCloseModal}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                id="payment-button-cancel"
                disabled={show_spinner}
                onClick={this.handleSaveUpload}
              >
                Upload File
              </Button>
            </Modal.Footer>
          </Modal>
        </div>

        <Modal
          show={show_edit_docu_modal}
          onHide={this.handleCloseEditModal}
          keyboard="true"
          size="xl"
        >
          <Modal.Header className="justify-content-center">
            <Modal.Title>Edit Documentation Entry</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <img
                src={edit_image_url}
                alt="docu_image"
                style={{ width: "100%", height: "100%", objectFit: "contain" }}
              />
              <label className="form-label">Summary</label>
              <input
                className="form-control"
                name="summary"
                value={summary}
                onChange={this.handleFormChange}
              />
            </div>
            <div>
              <label className="form-label">Description</label>
              <textarea
                className="form-control"
                name="description"
                value={description}
                onChange={this.handleFormChange}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              id="payment-button-cancel"
              onClick={this.handleCloseEditModal}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              id="payment-button-cancel"
              onClick={this.handleSaveEdit}
            >
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={show_delete_docu_modal}
          onHide={this.handleCloseDeleteModal}
          keyboard="true"
          size="xl"
        >
          <Modal.Header className="justify-content-center">
            <Modal.Title>Delete Documentation Entry</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5 className="text-center">
              Are you sure you want to Delete Entry?
            </h5>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              id="payment-button-cancel"
              onClick={this.handleCloseDeleteModal}
            >
              Cancel
            </Button>
            <Button
              variant="danger"
              id="payment-button-cancel"
              onClick={this.handleSaveDelete}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
            show={file_size_alert}
            onHide={this.handleCloseFileSizeAlert}
            keyboard="true"
            size="xs"
        >
          <Modal.Header className="justify-content-center">
            <Modal.Title>File Size Exceeded Alert</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5 className="text-center">
              Allowed File Size 80mb
            </h5>
          </Modal.Body>
          <Modal.Footer>
            <Button
                variant="primary"
                id="payment-button-cancel"
                onClick={this.handleCloseFileSizeAlert}
            >
              Okay
            </Button>

          </Modal.Footer>
        </Modal>

        <Row>
          {docu_files_final
            .sort(
              (a, b) =>
                b.utc_upload_time &&
                b.utc_upload_time.localeCompare(a.utc_upload_time)
            )
            .map((val, key) => (
              <Col sm={3} key={key}>
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip>
                      <div>{val.file_description}</div>
                    </Tooltip>
                  }
                  color="black"
                >
                  <div className="mb-3">
                    <Card>
                      {val.file_mime_type.includes("image") ? (
                        <img
                          src={val.presignedUrl_thumb}
                          alt={val.presignedUrl_thumb}
                          className="cover_image_documentation"
                          onClick={() => this.handleFileDownload(val)}
                        />
                      ) : val.file_mime_type.includes("pdf") ? (
                        <img
                          src="/img/file-icons/512px/pdf.png"
                          className="cover_image_documentation"
                          onClick={() => this.handleFileDownload(val)}
                          alt={val.presignedUrl_thumb}
                        />
                      ): val.file_mime_type.includes("csv") ? (
                          <img
                              src="/img/file-icons/512px/csv.png"
                              className="cover_image_documentation"
                              onClick={() => this.handleFileDownload(val)}
                              alt={val.presignedUrl_thumb}
                          />
                      ) : val.file_mime_type.includes("openxmlformats") ? (
                        <img
                          src="/img/file-icons/512px/xls.png"
                          className="cover_image_documentation"
                          onClick={() => this.handleFileDownload(val)}
                          alt={val.presignedUrl_thumb}
                        />
                      ) : (
                        <small>{val.file_mime_type}</small>
                      )}
                      <Card.Body>
                        <div>{val.file_summary}</div>
                        <div>
                          <small>
                            {"Submitted: " +
                              dayjs
                                .utc(val.utc_upload_time)
                                .format("MM-DD-YYYY HH:mm")}
                          </small>
                        </div>
                        <div>
                          <small>{"Filename: " + val.original_filename}</small>
                        </div>
                        <div>
                          <small>
                            {"Size: " +
                              NumberFormat().format(val.file_size_kb) +
                              "kb"}
                          </small>
                        </div>
                        <div>
                          <small>{"Uploaded: " + val.username}</small>
                        </div>
                      </Card.Body>
                      <Card.Footer>
                        <i
                          className="fas fa-edit me-2"
                          style={{ color: "blue", pointerEvents:"auto"}}
                          onClick={(e) => this.handleshowEditModal(val)}
                          aria-hidden="true"
                        />
                        <i
                          className="fa fa-trash "
                          style={{ color: "red", pointerEvents:"auto" }}
                          aria-hidden="true"
                          onClick={(e) => this.handleshowDeleteModal(val)}
                        />
                      </Card.Footer>
                    </Card>
                  </div>
                </OverlayTrigger>
              </Col>
            ))}
        </Row>
      </div>
    );
  }
}

export default Documentation;
