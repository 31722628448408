import React, {Component} from "react";
import Navigation from "../Navbar";
// import SiteSelect from "../Global/Site_select2";
// import PeriodSelect from "../Global/Period_select";
// import {Fetch} from "../../helpers/Fetch";
// import Select from "react-select";
// import {Button, Modal} from "react-bootstrap";

export default class Landlord_Update extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {

        return (
            <div>
                <Navigation/>
                <div className="row">
                    <p>IT WORKS</p>
                </div>
            </div>
        );
    }
}
