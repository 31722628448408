import React, {Component} from "react";
import {Fetch} from "../../helpers/Fetch";

class SiteNotesTextArea extends Component {
    constructor(props) {
        super(props);
        this.state = {
            site_notes: sessionStorage.getItem("prev_site_notes") !== 'null' ? sessionStorage.getItem("prev_site_notes") : '' ,
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.site_id !== this.props.site_id) {
            this.setState({
                site_notes: sessionStorage.getItem("prev_site_notes") !== 'null' ? sessionStorage.getItem("prev_site_notes") : '',
            });
        }
    }

    render() {
        if(!this.props.site_id) return
        const handleUpdate = ()=>{
            if(!this.state.site_notes) return;
            Fetch("functions_api.php", {
                action: "update_site_notes",
                site_id: sessionStorage.getItem("prev_site_id"),
                site_notes: this.state.site_notes
            })
                .then((data) => {
                    if (data.status === "ok") {
                        sessionStorage.setItem("prev_site_notes", this.state.site_notes);
                        this.props.handleSiteNotesChange(this.state.site_notes)
                    }
                })
                .catch((err) => console.log(err));
        }

        const { site_notes } = this.state
        return (
            <div className="input-group">
                <span className="input-group-text" style={{cursor: "pointer" }} onClick={handleUpdate}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        className="bi bi-floppy2-fill" viewBox="0 0 16 16">
                        <path d="M12 2h-2v3h2z"/>
                        <path d="M1.5 0A1.5 1.5 0 0 0 0 1.5v13A1.5 1.5 0 0 0 1.5 16h13a1.5 1.5 0 0 0 1.5-1.5V2.914a1.5
                        1.5 0 0 0-.44-1.06L14.147.439A1.5 1.5 0 0 0 13.086 0zM4 6a1 1 0 0 1-1-1V1h10v4a1 1 0 0 1-1 1zM3
                        9h10a1 1 0 0 1 1 1v5H2v-5a1 1 0 0 1 1-1"/>
                    </svg>
                </span>
                <textarea rows='2' className="form-control" value={site_notes}
                          onChange={(e) => {
                              this.setState({site_notes: e.target.value})
                          }}/>
            </div>
        );
    }
}

export default SiteNotesTextArea;
