import React, {Component} from 'react';
import '../styles/NotFoundPage.css'
import Navigation from "../components/Navbar";
import AppFooter from "../components/AppFooter";

class NotFound extends Component {
    render() {
        return (
            <div>
                <Navigation />
                <div id="not_found_main">
                    <div className="not_found_main_children">
                        <h1>Error 404 Page not found</h1>
                        <h6>contact <a href="mailto:support@energychaser.com">EnergyChaser support</a> </h6>
                    </div>
                </div>
                <AppFooter/>
            </div>
        );
    }
}

export default NotFound;